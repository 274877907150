import React from 'react';
import { Doctor, Product, UserType } from 'interfaces/api';
import { Guard } from 'containers';
import messages from 'messages';
import { Card, Control, Icon } from 'components';
import { faEnvelope, faFax, faFileMedicalAlt, faGlobe, faPhone, faTags, faUserCog } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router';
import cx from 'classnames';
import { useConfig } from 'providers';

interface Props {
  doctor: Doctor;
  selectedDoctor?: string;
  reset?: React.ReactNode;
}

export default React.memo((props: Props) => {

  const { selectedDoctor } = props;
  const { hideEmailFields } = useConfig();

  const {
    aid,
    externalId,
    displayName,
    email,
    phoneNumber,
    faxNumber,
    www,
    address,
    houseNumber,
    zipCode,
    city,
  } = props.doctor;

  const { push, location } = useHistory();
  return (

    <Card className={'info-popover'} title={selectedDoctor || displayName} extra={props.reset}>

      {externalId && (
        <h3>{externalId}</h3>
      )}

      {address} {houseNumber}
      <br/>
      {zipCode} {city}

      {phoneNumber && (
        <span>
          <Icon icon={faPhone}/>
          {phoneNumber}
        </span>
      )}

      {faxNumber && (
        <span>
          <Icon icon={faFax}/>
          {faxNumber}
        </span>
      )}

      {(email && !hideEmailFields) && (
        <a href={'mailto:' + email}>
          <Icon icon={faEnvelope}/>
          {email}
        </a>
      )}

      {www && (
        <a href={www} target={'_blank'} rel="noreferrer">
          <Icon icon={faGlobe}/>
          {www}
          <br/>
        </a>
      )}

      <div className={'margin-top-1'}>
        <Guard product={Product.OB} forbidden={[UserType.PAT, UserType.ARZ]}>
          <Control
            label={messages.reports.showAllReports}
            icon={faFileMedicalAlt}
            className={cx({ 'hidden-in-sidebar': location.pathname.startsWith('/ob') })}
            onClick={() => push({ pathname: '/ob', state: { doctors: { aid } } })}
          />
        </Guard>

        <Guard product={Product.OA} forbidden={[UserType.PAT]}>
          <Control
            icon={faTags}
            label={messages.cases.controls.orders}
            className={cx({ 'hidden-in-sidebar': location.pathname.startsWith('/oa') })}
            onClick={() => push({ pathname: '/oa', state: { doctors: { aid } } })}
          />
        </Guard>

        <Guard product={Product.ADMIN} forbidden={[UserType.PAT, UserType.ARZ]}>
          <Control
            icon={faUserCog}
            label={messages.admin.pages.users}
            onClick={() => {
              push('/admin/users/doctors/' + aid);
            }}
          />
        </Guard>
      </div>

    </Card>
  );
});
