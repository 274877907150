import constate from 'constate';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Loader } from 'components';
import { Translate } from '../IntlProvider';
import { ErrorBoundary } from 'containers';
import messages from 'messages';
import { Color } from 'interfaces';
import { CameraDevice, Html5Qrcode, Html5QrcodeCameraScanConfig } from 'html5-qrcode';

const barcodeContext = () => {

  const [available, setAvailable] = useState<boolean>(true);
  const [scanning, setScanning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [reader, setReader] = useState<Html5Qrcode>(null);
  const [cameras, setCameras] = useState<CameraDevice[]>([]); // [
  const scannerConfig: Html5QrcodeCameraScanConfig = { fps: 10, qrbox: 250, disableFlip: false };

  useEffect(() => {
    (async () => setAvailable((await listVideoInputDevices()).length > 0))();
  }, []);

  const scan = useCallback(async (codeScanned: (text: string) => void, scanElement?: HTMLDivElement) => {
    setLoading(true);
    setScanning(true);

    try {
      if (scanElement) {
        let cameraDevices = cameras;
        if (cameraDevices.length === 0) {
          cameraDevices = await Html5Qrcode.getCameras();
          setCameras(cameraDevices);
        }
        if (cameraDevices.length > 0) {
          setLoading(false);
          setAvailable(true);
          const html5QrcodeScanner = new Html5Qrcode(scanElement.id);
          await html5QrcodeScanner.start({ facingMode: 'environment' }, scannerConfig, codeScanned, () => {
            // NOOP, this will be triggered if no code could be found during scanning
          });
          setReader(html5QrcodeScanner);
        }
      } else {
        console.warn('No scan element provided, barcode scanner not available');
        setAvailable(false);
        setScanning(false);
      }
    } catch (e) {
      console.error(e);
      setAvailable(false);
      setLoading(false);
    }

  }, [available, reader, cameras]);

  const listVideoInputDevices = useCallback(async () => {
    const devices = await navigator.mediaDevices?.enumerateDevices() || [];
    const videoDevices: MediaDeviceInfo[] = [];

    for (const device of devices) {
      const kind = device.kind.toString() === 'video' ? 'videoinput' : device.kind;

      if (kind !== 'videoinput') {
        continue;
      }

      const deviceId = device.deviceId || (device as any).id;
      const label = device.label || `Video device ${videoDevices.length + 1}`;
      const groupId = device.groupId;
      const videoDevice = { deviceId, label, kind, groupId } as MediaDeviceInfo;

      videoDevices.push(videoDevice);
    }
    return videoDevices;
  }, []);

  useEffect(() => {
    if (!scanning && reader) {
      reader.stop().catch(console.error);
    }
  }, [reader, scanning]);

  const disable = useCallback(() => {
    setScanning(false);
  }, []);

  return {
    available,
    scan,
    loading,
    disable,
  };
};

const [BarcodeProvider, useBarcodeProvider] = constate(barcodeContext);

export {
  BarcodeProvider,
  useBarcodeProvider,
};

export type ScanPreviewProps = {
  className?: any;
  width?: string | number;
  height?: string | number;
  loaderColor?: Color;
  onScanned?: (data: string) => void;
};

const ScanPreviewContent: React.FC<ScanPreviewProps> = (props) => {
  const {
    className,
    width = '100%',
    height,
    onScanned,
    loaderColor,
  } = props;

  const barcodeProvider = useBarcodeProvider();
  const { available, loading } = barcodeProvider;

  useEffect(() => {
    return () => {
      barcodeProvider.disable();
    };
  }, []);

  const handleCodeScanned = (data: string): void => {
    onScanned(data);
  };

  const setElement = useCallback((videoElement: HTMLDivElement) => {
    barcodeProvider.scan(handleCodeScanned, videoElement);
  }, []);

  return (
    <div className={className} style={{ width }}>
      <div
        id={'qr-code-scanner'}
        ref={setElement}
        hidden={!available || loading}
        style={{ height, width }}
      />
      {loading && <Loader static color={loaderColor}/>}
      {(!available && !loading) && (
        <Alert type={'warning'} message={<Translate message={messages.errors.NoCameraError}/>}/>
      )}
    </div>
  );
};

export const ScanPreview: React.FC<ScanPreviewProps> = (props) => {

  return (
    <ErrorBoundary>
      <ScanPreviewContent {...props}/>
    </ErrorBoundary>
  );
};
