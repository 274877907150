import React, { useEffect, useState } from 'react';
import constate from 'constate';
import { Message } from 'interfaces';
import { ControlMenuProps } from 'components';

type BackLink = {
  title: Message;
  path: string;
};

const useTopBar = () => {

  const [backLink, setBackLink] = useState<BackLink>();
  const [isActive, setIsActive] = useState(false);
  const [menu, setMenu] = useState<ControlMenuProps>();

  return {
    isActive,
    setIsActive,
    backLink,
    setBackLink,
    menu,
    setMenu,
  };

};

const [TopBarProvider, useTopBarContext] = constate(useTopBar);

export {
  TopBarProvider,
  useTopBarContext,
};

export const TopBarBackLink: React.FC<{ backLink?: BackLink; children?: React.ReactNode }> = (props) => {

  const { backLink } = props;
  const { setBackLink, setIsActive } = useTopBarContext();

  useEffect(() => {

    setBackLink(backLink);
    setIsActive(true);

    return () => {
      setIsActive(false);
    };

  }, [backLink]);

  return (<>{props.children}</>);

};

export const TopBarControlMenu: React.FC<ControlMenuProps> = (props) => {

  const { setMenu } = useTopBarContext();

  useEffect(() => {

    setMenu(props);

    return () => {
      window.setTimeout(() => {
        setMenu(undefined);
      }, 200);
    };

  }, []);

  useEffect(() => {
    setMenu(props);
  }, [props.controls]);

  return (<>{props.children}</>);

};
