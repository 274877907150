import React, { useCallback } from 'react';
import { Button, Container, Icon, Modal } from 'components';
import { Translate } from 'providers/IntlProvider';
import messages from 'messages';
import { useBiometrics } from 'providers/BiometricsProvider';
import './FaceIdRequest.less';
import { faFingerprint, faFaceViewfinder } from '@fortawesome/pro-thin-svg-icons';

const labels = messages.auth.login;

export const FaceIDRequest: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { enable, faceIdRequestVisible, hideFaceIdRequest } = useBiometrics();

  const enableBiometrics = useCallback(async () => {
    enable();
    hideFaceIdRequest();
  }, [enable, hideFaceIdRequest]);

  return (
    <>
      {children}
      <Modal
        narrow={true}
        open={faceIdRequestVisible}
        className={'faceId-modal'}
        footer={
          (
            <Container grow shrink center className={'face-id-container'}>
              <Button type={'primary'} block onClick={enableBiometrics}>
                <Translate message={labels.faceIdRequest.useBiometrics}/>
              </Button>
              <a onClick={hideFaceIdRequest} className={'postpone-face-id margin-top-1'}>
                <Translate message={labels.faceIdRequest.postponeUseBiometrics}/>
              </a>
            </Container>
          )
        }
      >
        <Container grow shrink center className={'center faceId-container padding-top-3'} padding>
          <h1><Translate message={labels.biometrics}/></h1>
          <div className={'icon-wrapper padding-2'}>
            <Icon className={'biometrics-icon'} icon={faFingerprint} size={'4x'}/>
            <Icon className={'biometrics-icon'} icon={faFaceViewfinder} size={'4x'}/>
          </div>
          <p><Translate message={labels.faceIdRequest.description}/></p>
          <p><Translate message={labels.faceIdRequest.notice}/></p>
        </Container>
      </Modal>
    </>
  );
};
