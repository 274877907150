import React, { useEffect, useMemo, useState } from 'react';
import { ConnectionStatus, Network } from '@capacitor/network';
import makeError from 'make-error';
import constate from 'constate';
import { NetworkCheck } from './NetworkCheck';

export const NoNetworkError = makeError('NoNetworkError');

const networkContext = () => {

  const [status, setStatus] = useState<ConnectionStatus>();

  useEffect(() => {
    Network.addListener('networkStatusChange', (newStatus) => {
      setStatus(newStatus);
    });
    return () => {
      Network.removeAllListeners();
    };
  });

  useEffect(() => {
    (async () => setStatus(await Network.getStatus()))();
  }, []);

  const isConnected = useMemo(() => {
    return status?.connected ?? true;
  }, [status]);

  return { status, isConnected };
};

const [NetworkInternalProvider, useNetworkProvider] = constate(networkContext);

export {
  NetworkInternalProvider,
  useNetworkProvider,
};

export const NetworkCheckProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <NetworkInternalProvider>
      <NetworkCheck>
        {children}
      </NetworkCheck>
    </NetworkInternalProvider>
  );
};
