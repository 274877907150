import React from 'react';
import { AggregatedProfileType, AggregatedSuperRequirementProperties, OrderWizardRequirement, RequirementType } from 'interfaces/api';
import styles from './styles.module.less';
import { useBasketContext, useRequirementFilteredForms } from 'modules/orders/containers/OrderWizard/providers';
import { Header, HeaderProps, Icon } from 'components';
import cx from 'classnames';
import { faFileAlt, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { filter, find } from 'lodash';
import { isProfile, isSuperRequirement, useGetRequirementPriceBadge } from 'modules/orders/containers/OrderWizard/utils';
import { getRequirementShortName } from 'modules/orders/utils';
import messages from 'messages';
import { Translate } from 'providers';
import { useRequirementHeaderControls } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/ControlsMenu.tsx';
import { useEnv } from 'providers/EnvProvider';
import { Color } from 'interfaces';

export const PopOverHeader = (props: {
  requirement: OrderWizardRequirement | AggregatedSuperRequirementProperties;
  profileId?: number;
  superRequirementId?: number;
  basket?: boolean;
  showControls?: boolean;
}) => {

  const { requirement, basket, profileId } = props;

  const { getFlag } = useOrdersContext();
  const filterForms = useRequirementFilteredForms();
  const getRequirementPriceBadge = useGetRequirementPriceBadge();

  const lid = useAuthStoreSelectors.lid();
  const endpoint = useEnv.endpoint();

  const { wizardSettings: { forms, preferences: { orderWizardWrapPopoverHeader, orderWizardUnwrapListTitle } } } = useOfficeDoctorContext();

  const isNormalRequirement = !isProfile(requirement) && !isSuperRequirement(requirement);

  const images = isNormalRequirement
    ? filter((requirement.materials || []).map((material) => {
      return `${endpoint}/api/orders/samples/${material.sampleId}/image/small?lid=${lid}`;
    }))
    : undefined;

  const availableForms = isNormalRequirement ? filterForms(requirement).map(id => find(forms, { id })) : [];

  const formTitle = availableForms.length === 1
    ? (
      <span className={styles.formSubtitle}>
        <Icon icon={faFileAlt}/>
        <Translate message={messages.orders.wizard.formCostUnitFormat} values={{ name: availableForms[0]?.name, costUnit: availableForms[0]?.costUnit }}/>
      </span>
    )
    : undefined;

  const { profileRequirementsPartialInBasket, isInBasket } = useBasketContext();

  const faded = isProfile(requirement) ? profileRequirementsPartialInBasket(requirement) : isInBasket(requirement);
  const controls = useRequirementHeaderControls()(requirement, profileId);

  const isCancelled = isNormalRequirement && requirement.cancelled_at;

  const headerProps: HeaderProps = {
    images,
    controls: props.showControls ? controls : null,
    flag: isNormalRequirement ? getFlag(requirement) : undefined,
    title: <>{requirement.longName}{isCancelled ? <Icon icon={faTimesCircle} color={Color.Red} className={'margin-left-1'}/> : null}</>,
    subtitle: <>{getRequirementShortName(requirement)}{formTitle}{isNormalRequirement ? getRequirementPriceBadge(requirement) : null}</>,
    wrapTitle: orderWizardWrapPopoverHeader,
    maxVisibleControls: controls.length,
    className: cx(styles.header, {
      [styles.isProfile]: isProfile(requirement),
      [styles.mibiItem]: !isProfile(requirement) && requirement.entityType === RequirementType.Microbiological,
      [styles.faded]: (basket && !faded) || (!basket && faded) || isCancelled,
      [styles.isGlobalProfile]: isProfile(requirement) && requirement.entityType === AggregatedProfileType.Global,
      [styles.isRequirementCollection]: isSuperRequirement(requirement),
      [styles.unwrapHeader]: orderWizardUnwrapListTitle,
    }),
  };

  return <Header {...headerProps}/>;

};
