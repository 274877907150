import {
  Gender,
  KbvCovidLegalBasis,
  KbvCovidRvo,
  KbvCovidLocation,
  KbvCovidLocationFacility,
  KbvCovidTest,
  KbvCovidTestReason,
  LanguageCode,
  UserType,
  Interval, BeforeAfterDialysis,
} from 'interfaces/api';
import { FingerprintType } from 'interfaces';

export default {

  i18n: {
    locale: 'Locale',
    locales: {
      [LanguageCode.EN]: 'English',
      [LanguageCode.DE]: 'German',
      [LanguageCode.HU]: 'Hungarian',
      [LanguageCode.IT]: 'Italian',
      [LanguageCode.NL]: 'Dutch',
      [LanguageCode.FR]: 'French',
    },
  },

  or: 'or',
  enterText: 'Enter text',
  title: 'Title',

  administration: 'Administration',
  messageCenter: 'Message Center',
  settings: 'Settings',

  notImplemented: 'Sorry, this function is disabled on that server.',
  validEmail: 'Please enter a valid email',

  toc: 'Terms and Conditions',
  privacy: 'Privacy Statement',
  contact: 'Contact',
  contactForm: {
    label: 'Contact Form',
    title: 'Contact Form',
    notifications: {
      success: 'Contacted laboratory successfully',
    },
    description: 'Here you can contact your laboratory',
    subject: 'Subject',
    message: 'Message',
  },

  passwordPolicy: {
    label: 'Password policy',
    description: 'The password must contain at least 8 characters, one upper and one lower case letter, one number and a special sign.',
  },

  topBar: {
    changeLanguage: 'Change language',
    editProfile: {
      avatar: 'Avatar',
      deleteAvatar: 'Delete Avatar',
      deleteAvatarConfirm: 'Do you really want to remove the avatar?',
      title: 'Edit profile and settings',
      profile: 'Profile',
      personalData: 'Personal information',
      editPersonalData: 'Edit personal information',
      security: 'Security',
      startProduct: 'Start product',
      noStartProductSelected: 'No start product selected',
      changeStartProduct: 'Change start product',
      password: 'Password',
      changePassword: {
        title: 'Change password',
        oldPassword: 'Please enter your current password',
        newPassword: 'Please enter your new password',
        notifications: {
          success: 'Your password has been changed',
        },
      },
      email: 'E-Mail',
      website: 'Website',
      smsNumber: 'SMS Number',
      groups: 'Groups',
      notificationSettings: {
        title: 'Notifications',
        change: 'Notification Settings',
      },
      twoFactorAuth: {
        label: 'Two-Factor-Authentication',
        activateNow: 'Activate now',
        enable: {
          label: 'Enable 2FA',
          success: {
            title: 'Successfully enabled 2fa',
            content: 'Upon next login you will have to enter your authenticator key',
          },
        },
        disable: {
          label: 'Disable 2FA',
          confirm: {
            title: 'Do you really want to disable 2FA?',
          },
          success: {
            title: 'Successfully disabled 2FA',
            content: 'You can now login without 2FA',
          },
        },
        qrcode: 'Step 1: Scan the QR Code in your Authenticator app',
        manual: 'or enter the secret key manually:',
        enterToken: 'Step 2: Enter the key from your Authenticator app',
      },
    },
    logout: 'Logout',
    dashboard: 'Dashboard',
    admin: 'Administration',
    messages: 'Messages',
    notifications: 'Notifications',
    notificationSettings: 'Notification Settings',
    delivery: {
      form: {
        title: 'Book delivery service',
        date: 'Today',
        time: 'Now',
        subject: 'Book courier service',
        comment: 'Comment',
        button: 'Book',
        success: 'Delivery service successfully booked',
        error: 'An error occured while booking delivery service',
      },
    },
  },

  interval: {
    [Interval.Year]: 'Years',
    [Interval.Month]: 'Months',
    [Interval.Week]: 'Weeks',
    [Interval.Day]: 'Days',
    [Interval.Hour]: 'Hours',
    [Interval.Minute]: 'Minutes',
    [Interval.Second]: 'Seconds',
  },

  dateFormat: {
    blocks: {
      D: 'D',
      M: 'M',
      Y: 'Y',
      h: 'h',
      m: 'm',
    },
  },

  gender: {
    [Gender.Unknown]: 'Unknown',
    [Gender.Male]: 'Male',
    [Gender.Female]: 'Female',
    [Gender.Undetermined]: 'Undetermined',
    [Gender.Diverse]: 'Diverse',
  },

  kbvCovidTest: {
    [KbvCovidTest.First]: 'First test',
    [KbvCovidTest.Further]: 'Further test',
  },

  kbvCovidLegalBasis: {
    [KbvCovidLegalBasis.RvoTestV]: 'TestV',
    [KbvCovidLegalBasis.DirectPayer]: 'Direct Payer',
    [KbvCovidLegalBasis.RegionalSpecialAgreement]: 'Regional Special Agreement',
  },

  kbvCovidLocation: {
    [KbvCovidLocation.CaredFor]: 'Cared For',
    [KbvCovidLocation.Working]: 'Working',
  },

  kbvCovidLocationType: {
    [KbvCovidLocationFacility.Medical]: 'Medical',
    [KbvCovidLocationFacility.Community]: 'Community',
    [KbvCovidLocationFacility.CareOrLiving]: 'CareOrLiving',
    [KbvCovidLocationFacility.Other]: 'Other',
  },

  kbvCovidTestReason: {
    [KbvCovidTestReason.WarnAppIncreasedRisk]: 'Increased risk',
    [KbvCovidTestReason.DiagnosticConfirmation]: 'Diagnostic confirmation',
  },

  kbvCovidRvo: {
    [KbvCovidRvo.ContactPerson]: 'Contractperson',
    [KbvCovidRvo.WarnAppIncreasedRisk]: 'Warn increased risk',
    [KbvCovidRvo.Outbreak]: 'Outbreak happened',
    [KbvCovidRvo.DistributionPrevention]: 'Distribution prevention',
    [KbvCovidRvo.RiskArea]: 'Risk area',
    [KbvCovidRvo.ForeignRiskArea]: 'Foreign risk area',
  },

  meta: {
    copyright: '© {YEAR} labuniq, v{VERSION}',
    overwriteLinks: '',
  },

  search: {
    placeholder: 'Search...',
    autocomplete: {
      default: 'Free text search',
      tnr: 'Order Number:',
      patientName: 'Patient name',
      patientNameOrEmail: 'Patient name/email',
      exttnr: 'External Order Number:',
      pident: 'PIDENT:',
      case_number: 'Case Number:',
      insurance_number: 'Insurance Number:',
      requirement: {
        label: 'Requirement',
        exists: '{name} exists',
        pathological: '{name} is pathological',
        extreme: '{name} is extreme',
        equalsNumber: '{name} equals number {value}',
        equalsText: '{name} text like ...{value}...',
        greaterThan: '{name} greater than {value}',
        lowerThan: '{name} lower than {value}',
      },
      groups: {
        patients: 'Patients',
        doctors: 'Doctors',
        analyses: 'Analyses',
      },
      close: 'Reset Selection',
    },
    dateSearch: 'Search for date',
    searchModal: {
      title: 'Scan Barcode',
    },
  },

  save: 'Save',
  close: 'Close',
  apply: 'Apply',
  applyChanges: 'Apply changes',
  back: 'Back',
  reset: 'Reset',
  reload: 'Reload',
  reloadPage: 'Reload page',
  send: 'Send',
  ok: 'OK',
  cancel: 'Cancel',
  next: 'Next',
  alright: 'Alright',
  confirm: 'Confirm',
  download: 'Download',
  downloadFilename: 'Click here to download the file {FILE}',
  saveSuccess: 'Saved successfully',

  selectAll: 'Select all',
  deselectAll: 'Deselect all',

  all: 'All',
  none: 'None',
  noneSelected: 'None selected',
  notSpecified: 'not specified',

  new: 'New',
  readMore: 'read more',

  created_at: 'Creation date',

  sidebar: {
    collapse: 'Collapse Sidebar',
  },

  list: {
    pinnedHeader: 'Pinned items',
    noResults: 'No results',
  },

  sort: {
    label: 'Sort by',
    asc: 'Ascending',
    desc: 'Descending',
    kennung: 'Login ID',
    name: 'Name',
    email: 'E-Mail',
  },

  filter: {
    label: 'Filter',
    quick: 'Quick Filters',
    placeholder: 'Select {title}',
    date: 'Date',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    doctorName: 'Doctor Name',
  },

  date: 'Date',
  email: 'E-Mail',

  age: 'Age',
  ageInYears: '{YEARS, plural, one {# year} other {# years}} old',
  ageInMonths: '{MONTHS, plural, one {# month} other {# months}} old',

  diagnosis: 'Diagnosis',
  caseNumber: 'Case number',

  form: {
    dynamic: {
      addLabel: 'Add field',
      remove: 'Remove',
      move: 'Move',
    },
    fileSelect: {
      tap: 'Tap here to select a file',
      drag: 'Click or drag file to this area to upload',
    },
    defaultValue: 'Default value: <b>{value}</b>',
  },

  fingerprint: {
    enable: 'Enable {TYPE}',
    disable: 'Disable {TYPE}',
    types: {
      [FingerprintType.Finger]: 'Fingerprint',
      [FingerprintType.TouchID]: 'Touch ID',
      [FingerprintType.FaceID]: 'Face ID',
    },
  },

  fileTransfer: {
    download: 'Download in progress',
    upload: 'Upload in progress',
    processing: 'Processing file',
    preparing: 'Preparing file transfer',
  },

  embed: {
    notSupported: {
      title: 'Preview not possible',
      description: 'This file type is not supported for instant preview. Please download it and open it on your computer.',
    },
  },

  userSelect: {
    labels: {
      select: 'Select',
    },
  },

  userTypes: {
    single: {
      [UserType.LAB]: 'Laboratory',
      [UserType.LAU]: 'Laboratory User',
      [UserType.ARZ]: 'Doctor',
      [UserType.PAT]: 'Patient',
      [UserType.ADM]: 'Administrator',
      [UserType.SAD]: 'Administrator',
    },
    plural: {
      [UserType.LAB]: 'Laboratories',
      [UserType.LAU]: 'Laboratory Users',
      [UserType.ARZ]: 'Doctors',
      [UserType.PAT]: 'Patients',
      [UserType.ADM]: 'Administrators',
      [UserType.SAD]: 'Administrators',
    },
  },
  contactLaboratory: {
    title: 'Contact laboratory',
    subject: 'Subject',
    content: 'Content',
  },

  exportPdf: 'Export PDF',

  inputError: 'Does not match the desired pattern.',

  person: {
    lastName: 'Last name',
    firstName: 'First name',
    title: 'Title',
    salutation: 'Salutation',
    prefix: 'Prefix',
    suffix: 'Suffix',
    address: 'Address',
    street: 'Street',
    houseNumber: 'House number',
    addressAdditional: 'Address additional info',
    zip: 'Zip code',
    city: 'City',
    birthday: 'Birthday',
    sex: 'Sex',
    country: 'Country',
    phone: 'Phone number',
    fax: 'Fax number',
    email: 'Email address',
    size: 'Size',
    weight: 'Weight',
    insuranceNumber: 'Insurance number',
    accidentTime: 'Accident time',
    documentReferenceNumber: 'Document reference number',
    passportNumber: 'Passport Number',
    idcardNumber: 'ID Card Number',
  },
  animal: {
    title: 'Animal',
    owner: {
      title: 'Animal owner',
      name: 'Animal owner name',
      amendment: 'Animal owner amendment',
    },
    name: 'Animal name',
    birthday: 'Animal birthday',
    sex: 'Animal sex',
    species: 'Animal species',
    chipId: 'Animal chip number',
    breed: 'Animal breed',
    size: 'Animal size',
    weight: 'Animal weight',
  },

  updateAvailableModal: {
    title: 'Update available',
    content: 'A new version ({availableVersion}) is available to be installed. Your version is {currentVersion}. Please update to use this app.',
    updateNow: 'Update now',
  },

  newVersionAvailableModal: {
    title: 'Update available',
    content: 'A new version is available. Please reload this page to get the newest experience.',
    reloadNow: 'Reload now',
  },

  noPatientSelected: 'No patient selected',

  pullToRefresh: {
    pullDownCaption: 'Pull down to refresh',
    releaseCaption: 'Release to refresh',
  },

  beforeAfterDialysis: {
    [BeforeAfterDialysis.Before]: 'Before',
    [BeforeAfterDialysis.After]: 'After',
  },

  multiBackend: {
    wrongVersion: {
      title: 'Wrong version on backend',
      content: 'The backend version differs to the frontend. Please contact your laboratory.',
    },
  },

};
