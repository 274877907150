import constate from 'constate';
import { useToggle } from 'hooks';

const useWizardToggle = ({ visible }: { visible: boolean }) => useToggle(visible);

const [WizardToggleProvider, useWizardToggleContext] = constate(useWizardToggle);

export {
  WizardToggleProvider,
  useWizardToggleContext,
};
