import React, { useEffect, useRef } from 'react';
import './PinField.less';
import BasePinField, { PinFieldProps as BasePinFieldProps } from 'react-pin-field';
import { Container } from 'components/Container';

type OverwriteProps = {
  prefix?: string;
  usePrefix?: boolean;
  suffix?: string;
  useSuffix?: boolean;
  bindReset?: (reset: () => void) => void;
};

export type PinFieldProps = Omit<BasePinFieldProps, keyof OverwriteProps> & OverwriteProps;

const PinFieldAutoBlur: React.FC<PinFieldProps> = (props) => {

  const ref = useRef<HTMLInputElement[] | null>();

  const onResolveKey = (key: string, inputRef?: HTMLInputElement) => {
    if (inputRef === ref.current[ref.current.length - 1]) {
      window.setTimeout(() => ref.current[ref.current.length - 1].blur());
    }
  };

  useEffect(() => {
    if (props.autoFocus) {
      window.setTimeout(() => ref.current[0].focus());
    }
    if (props.bindReset) {
      props.bindReset(() => {
        ref.current.forEach(input => (input.value = ''));
      });
    }
  });

  return (
    <BasePinField {...props} ref={ref} onResolveKey={onResolveKey}/>
  );

};

export const PinField: React.FC<PinFieldProps> = (props) => {

  const { prefix, usePrefix, suffix, useSuffix, onChange, disabled, ...outerProps } = props;

  const onComplete = (code: string) => {
    onChange([usePrefix ? prefix : '', code, useSuffix ? suffix : ''].join(''));
    outerProps.onComplete?.(code);
  };

  return (
    <Container className={'pin-field'} style={{ gridTemplateColumns: `repeat(${(prefix?.length || 0) + props.length + (suffix?.length || 0)}, minmax(2rem, 1fr))` }}>
      {prefix && (prefix.split('').map(c => <input key={c} type="text" autoCapitalize="off" value={c} placeholder="•" pattern="[a-zA-Z0-9]*" disabled/>))}
      <PinFieldAutoBlur {...{ ...outerProps, disabled, onComplete }} />
      {suffix && (suffix.split('').map(c => <input key={c} type="text" autoCapitalize="off" value={c} placeholder="•" pattern="[a-zA-Z0-9]*" disabled/>))}
    </Container>
  );
};
