import React, { useCallback, useEffect, useState } from 'react';
import constate from 'constate';
import { FingerprintType } from 'interfaces';
import { Platform } from 'interfaces/api';
import { useStorage } from 'providers/StorageProvider/StorageProvider';
import { useApiClient } from 'providers/ApiProvider/useApiClient';
import { FaceIDRequest } from './FaceIDRequest';
import { useEnv } from 'providers/EnvProvider';

interface FingerprintOptions {
  title?: string;
  subtitle?: string;
  description?: string;
  fallbackButtonTitle?: string;
}

const biometricsContext = () => {

  const [available, setAvailable] = useState<FingerprintType>();
  const [enabled, setEnabled] = useState(false);
  const [faceIdRequestVisible, setFaceIdRequestVisible] = useState(false);
  const plugin = (window as any).Fingerprint;

  const platform = useEnv.platform();
  const storage = useStorage();
  const { devices: { createDeviceToken } } = useApiClient();

  useEffect(() => {
    if (platform !== Platform.WEB) {
      plugin.isAvailable((result: string) => {
        if (result === 'face') {
          setAvailable(FingerprintType.FaceID);
        } else if (result === 'finger' && platform === Platform.IOS) {
          setAvailable(FingerprintType.TouchID);
        } else {
          setAvailable(FingerprintType.Finger);
        }
      }, () => {
        setAvailable(undefined);
      });
    }
  });

  useEffect(() => {
    if (available !== undefined) {
      storage.getToken().then(credentials => setEnabled(!!credentials));
    }
  }, [available]);

  const show = useCallback((options: FingerprintOptions) => new Promise((resolve, reject) => {
    if (available !== undefined) {
      plugin.show(options, resolve, reject);
    }
  }), [available]);

  const showFaceIdRequest = useCallback(async () => {
    if (available !== undefined && !await storage.getToken()) {
      setFaceIdRequestVisible(true);
      await storage.setFaceIdLoginRequested();
    }
  }, [available, storage]);

  const hideFaceIdRequest = useCallback(async () => {
    setFaceIdRequestVisible(false);
    await storage.setFaceIdLoginRequested();
  }, [storage]);

  const enable = useCallback(async () => {
    try {
      await show({});
      const token = await createDeviceToken();
      await storage.setToken(token.token);
      setEnabled(true);
    } catch (e) {
      setEnabled(false);
      console.error(e);
    }
  }, [storage, createDeviceToken, show]);

  const disable = useCallback(async () => {
    await storage.removeToken();
    await storage.removeFaceIdLoginRequested();
    setEnabled(false);
  }, [storage]);

  return {
    available,
    enabled,
    show,
    enable,
    disable,
    showFaceIdRequest,
    hideFaceIdRequest,
    faceIdRequestVisible,
  };

};

const [BiometricsInternalProvider, useBiometrics] = constate(biometricsContext);
export { useBiometrics };

export const BiometricsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  return (
    <BiometricsInternalProvider>
      <FaceIDRequest>
        {children}
      </FaceIDRequest>
    </BiometricsInternalProvider>
  );
};
