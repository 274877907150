import React from 'react';
import { AlertModal } from 'components';
import { NoNetworkError, useNetworkProvider } from './NetworkCheckProvider';

export const NetworkCheck: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { isConnected } = useNetworkProvider();

  return (
    <>
      {children}
      <AlertModal
        showIcon
        open={!isConnected}
        error={new NoNetworkError()}
      />
    </>
  );
};
