import React from 'react';
import messages from 'messages';
import { useBasketContext } from 'modules/orders/containers/OrderWizard/providers';
import { Control } from 'components';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

const labels = messages.orders.controls.clearBasket;

export const ClearBasket = () => {

  const { clearBasket } = useBasketContext();

  return (
    <Control
      icon={faTrashAlt}
      label={labels.label}
      onClick={clearBasket}
      tooltip={{ placement: 'left' }}
    />
  );
};
