import React, { useCallback } from 'react';
import { AggregatedRequirementFlags, OrderWizardRequirement } from 'interfaces/api';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarFull } from '@fortawesome/pro-solid-svg-icons';
import { Color } from 'interfaces';
import { toggleArrayItem } from 'utils/helpers';
import { Control } from 'components/Control';
import messages from 'messages';
import { useApi } from 'providers';
import { useListLayoutContext } from 'modules/orders/containers/OrderWizard/providers';
import { useOfficeDoctorContext } from 'modules/orders/providers';
import { getRequirementId } from 'modules/orders/utils';

export const TogglePin = (props: { requirement: OrderWizardRequirement }) => {

  const { requirement } = props;

  const { requirements: { pinRequirement, unpinRequirement } } = useApi();
  const { officeDoctor: { aid } } = useOfficeDoctorContext();
  const { bindings: { updateItem } } = useListLayoutContext().getListLayoutArgs();

  const onClick = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();

    const request = requirement.flags.includes(AggregatedRequirementFlags.IsPinned) ? unpinRequirement : pinRequirement;
    await request({ aid, id: requirement.entityId, aggregatedId: requirement.id, type: requirement.entityType });

    const key = getRequirementId(requirement);
    updateItem(key, { ...requirement, flags: toggleArrayItem(requirement.flags, AggregatedRequirementFlags.IsPinned) });

  }, [requirement, unpinRequirement, pinRequirement, updateItem]);

  return (
    <Control
      icon={requirement.flags?.includes(AggregatedRequirementFlags.IsPinned) ? { icon: faStarFull, color: Color.Blue } : faStar}
      label={messages.orders.toggleFavorite}
      tooltip={{ placement: 'top' }}
      onClick={onClick}
    />
  );

};
