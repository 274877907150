import { useAuthStore } from 'modules/auth/store';
import { isLegacyLoginToken, LegacyLoginAid, LegacyLoginToken } from 'modules/auth/utils.tsx';
import queryString from 'query-string';
import { arrayify, getWizardStateFromGetParams } from 'utils/helpers.ts';
import { omit } from 'lodash';
import { ExchangeFileType, OrderChangeAction } from 'interfaces/api';
import dayjs from 'dayjs';
import { ExchangeLocationState } from 'modules/exchange';
import { ReportLocationState } from 'modules/reports';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useApiClient } from 'providers/ApiProvider/useApiClient.tsx';

export const useLegacyRoutes = () => {

  const { legacy: isLegacy, resolveLegacy, rejectLegacy, setUser, setLegacy } = useAuthStore.getState();

  const location = useLocation();

  const history = useHistory();

  const { authentication: { exchangeToken, createLegacyToken } } = useApiClient();

  const checkLegacy = async () => {

    const legacyLogin = async (data: LegacyLoginAid | LegacyLoginToken) => {

      setLegacy(true);

      const user = await (() => {
        if (isLegacyLoginToken(data)) {
          return exchangeToken(data);
        } else {
          return createLegacyToken(data);
        }
      })();

      setUser(user, true);

    };

    if (!isLegacy) {

      const { pathname, search } = location;

      const params = queryString.parse(search, { arrayFormat: 'bracket' });

      if (params.token) {
        // exchange token
        const state: any = {};
        try {
          await legacyLogin({ token: arrayify(params.token)[0] });
          resolveLegacy();
          history.replace({ pathname, state, search: queryString.stringify(omit(params, 'token')) });
        } catch (e) {
          rejectLegacy(e);
        }

      } else if (pathname.startsWith('/index')) {

        if (params.ID && params.key && params.url) {

          try {
            await legacyLogin({ aid: arrayify(params.ID)[0], key: arrayify(params.key)[0] });
          } catch (e) {
            rejectLegacy(e);
          }

          try {

            const el = document.createElement('a');
            el.href = 'http://localhost/' + decodeURIComponent((params.url as string).replace(/\+/g, ' '));

            const { func, cache, ...legacyParams } = queryString.parse(el.search);

            if (el.pathname === '/onlineauftrag/index.php') {

              if (func === 'neuerpoolauftrag' || func === 'neuerauftrag') {
                const params = getWizardStateFromGetParams(legacyParams);
                params.showWizard.logs.push({ action: OrderChangeAction.Gdt2OA, created_at: dayjs().toISOString() });
                history.replace('/oa', params);
              }

            } else if (el.pathname === '/labexchange/index.php') {

              const locationState: ExchangeLocationState = {};

              if (func === 'listFiles') {
                locationState.fileType = ExchangeFileType.LDT;
              } else if (func === 'listPDFFiles') {
                locationState.fileType = ExchangeFileType.PDF;
              } else if (func === 'sonstigefiles') {
                locationState.fileType = ExchangeFileType.OTHER;
              }

              history.replace('/le', locationState);

            } else if (el.pathname === '/onlinebefunde/index.php') {

              const locationState: ReportLocationState = {};

              if (func === 'patienten') {
                locationState.patients = { pid: parseInt(legacyParams.pid as string) };
              }

              history.replace('/ob', locationState);

            }
          } catch (e) {
            resolveLegacy();
          }

        }

      } else if (pathname.startsWith('/onlinebefunde/launcher.php')) {

        if (params.loadaisimport && params.id && params.key) {

          const loadAisImport = params.loadaisimport as string;
          const loadAisFirstname = params.aisfirstname as string;
          const loadAisLastname = params.aislastname as string;
          const loadAisBirthdate = params.aisbirthdate as string;
          const loadAisShowReport = (params.handle === 'befund');

          await legacyLogin({ aid: arrayify(params.id)[0], key: arrayify(params.key)[0] });

          try {
            if (loadAisImport === '1') {
              const locationState: ReportLocationState = {
                loadAisImport: { loadAisFirstname, loadAisLastname, loadAisBirthdate, loadAisShowReport },
              };

              history.replace('/ob', locationState);
            }
          } catch (e) {
            resolveLegacy();
          }
        }

      }
    }

  };

  useEffect(() => {
    checkLegacy();
  }, [location]);

};
