import React from 'react';
import { AnamnesisProvider, OfficeDoctorProvider, OrdersInitialValue, OrdersProvider, WizardToggleProvider } from 'modules/orders/providers';
import { OrderRulesProvider } from 'modules/orders/providers/OrderRulesProvider';
import { OrderRuleModal } from 'modules/orders/components/OrderRuleModal';
import { withSuspense } from 'hocs';
import { Doctor } from 'interfaces/api';
import { ListLayoutArgs } from 'containers';
import { OrderLocationState, OrdersListContext } from 'modules/orders/index';
import { Compose } from 'containers/Compose';
import { OrdersListProvider } from 'modules/orders/providers/OrdersListProvider';

const OrderWizard = withSuspense(React.lazy(() => import('../OrderWizard')));

export type OrderRootProps = {
  initialOrder?: OrdersInitialValue;
  initialOfficeDoctor?: Doctor;
  children: React.ReactNode;
  listRef?: React.MutableRefObject<ListLayoutArgs<OrdersListContext>>;
  locationState?: OrderLocationState;
};

export const OrdersRoot: React.FC<OrderRootProps> = props => (
  <Compose
    components={[
      [WizardToggleProvider, { visible: !!props.locationState?.showWizard }],
      [OrdersListProvider, { listRef: props.listRef }],
      [OfficeDoctorProvider, { officeDoctor: props.initialOfficeDoctor }],
      [OrdersProvider, props.initialOrder],
      OrderRulesProvider,
      AnamnesisProvider,
    ]}
  >

    {props.children}

    <OrderWizard/>

    <OrderRuleModal/>

  </Compose>
);
