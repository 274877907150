import React, { useCallback } from 'react';
import { Collapse, Container, Icon, Search, SearchProps } from 'components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import styles from './SideBar.module.less';
import cx from 'classnames';
import { Breakpoint } from 'interfaces';
import { Translate } from 'providers';
import { Toggle } from 'hooks';
import { Guard, isInstanceOfSubRoute, SubRoute } from 'containers';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { filter } from 'lodash';
import { createRandomString } from 'utils/helpers';

export type SideBarProps = {
  items?: (SubRoute | React.ReactNode)[];
  children?: React.ReactNode;
  toggle?: Toggle;
  className?: any;
  inverted?: boolean;
  breakpoint?: Breakpoint;
  isRight?: boolean;
  search?: SearchProps;
};

export const SideBar = (props: SideBarProps) => {

  const { items, children, className, inverted, breakpoint, isRight, search } = props;

  const [toggled, toggle] = props.toggle || [undefined, undefined];

  const { pathname } = useLocation();

  const hasChildren = (item: SubRoute) => item?.children?.length > 0;
  const isActive = useCallback((item: SubRoute) => (pathname + '/').indexOf(item.path + '/') === 0, [pathname]);

  const containerClasses = cx(styles.container, 'sidebar', className, {
    [styles.isToggled]: toggled,
    [styles.isRight]: isRight,
    [styles.inverted]: inverted,
  }, breakpoint && styles[breakpoint]);

  return (
    <Container className={containerClasses}>

      {search && (
        <Container shrink>
          <Search {...search} className={styles.search}/>
        </Container>
      )}

      {items?.length > 0 && (
        <Container grow reset>
          <Container scrollY>
            <ul className={styles.nav}>
              {filter(items.map((item, index) => {
                if (isInstanceOfSubRoute(item) && !item.navHidden) {
                  const parentActive = isActive(item) || filter(item.children, child => isActive(child)).length > 0;

                  const onClick = () => toggled && toggle();
                  const parentWrapper = (content: any) => item.path
                    ? (
                      <Link to={{ pathname: item.path, state: { key: createRandomString() } }} onClick={onClick}>
                        {content}
                      </Link>
                    )
                    : (
                      <a>{content}</a>
                    );

                  const header = parentWrapper((
                    <>
                      {item.icon && <span className={'control-icon'}><Icon {...item.icon}/></span>}
                      {item.title && <span className={'control-label'}><Translate message={item.title}/></span>}
                    </>
                  ));

                  return (
                    <Guard key={index} {...item.guard}>
                      <li className={cx(item.navClassName, { [styles.isActive]: parentActive, [styles.hasChildren]: hasChildren(item) })}>
                        {hasChildren(item)
                          ? (
                            <Collapse
                              activeKey={parentActive ? ['1'] : []}
                              items={[{
                                key: '1',
                                label: header,
                                showArrow: false,
                                children: (
                                  <ul>
                                    {item.children.map(child => (
                                      <Guard key={child.path} {...child.guard}>
                                        <li className={cx({ [styles.isActive]: isActive(child) })}>
                                          <Link to={{ pathname: child.path, state: { key: createRandomString() } }} onClick={onClick}>
                                            <Icon icon={faChevronRight}/>
                                            <Translate message={child.title}/>
                                          </Link>
                                        </li>
                                      </Guard>
                                    ))}
                                  </ul>
                                ),
                              }]}
                            />
                          )
                          : (
                            <>{header}</>
                          )}
                      </li>
                    </Guard>
                  );
                }

                return React.isValidElement(item) ? item : null;
              }))}
            </ul>
          </Container>
        </Container>
      )}

      {children}

    </Container>
  );
};
