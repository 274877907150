import { useCallback, useRef } from 'react';
import { ListLayoutArgs } from 'containers';
import constate from 'constate';

const useListLayout = () => {

  const listLayoutArgs = useRef<ListLayoutArgs<any>>({ context: undefined, bindings: undefined });

  const setListLayoutArgs = (args: ListLayoutArgs<any>) => {
    listLayoutArgs.current = args;
  };

  const getListLayoutArgs = useCallback(() => listLayoutArgs.current, [listLayoutArgs.current]);

  return { getListLayoutArgs, setListLayoutArgs };
};

const [ListLayoutProvider, useListLayoutContext] = constate(useListLayout);

export {
  ListLayoutProvider,
  useListLayoutContext,
};
