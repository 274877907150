import { useEffect } from 'react';
import { cloneDeep, find, set } from 'lodash';
import { useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { useBasketRequirements } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useBasketRequirements';

export const useSetInvoiceTo = (invoiceToActive: boolean) => {

  const { wizardSettings } = useOfficeDoctorContext();
  const basketRequirements = useBasketRequirements();

  const { setOrder, setAllOrSelectedOrders, currentOrder } = useOrdersContext();

  // set invoice to
  useEffect(() => {
    if (invoiceToActive && basketRequirements.length > 0 && !currentOrder?.patient?.invoiceTo?.length) {
      const form = find(wizardSettings?.forms, { id: basketRequirements[0].formId });
      const invoiceTo = wizardSettings?.preferences?.orderWizardDefaultInvoiceTo || form?.defaultInvoiceTo;
      if (invoiceTo) {
        setAllOrSelectedOrders(order => set(cloneDeep(order), 'patient.invoiceTo', invoiceTo));
      }
    }
  }, [basketRequirements, wizardSettings, invoiceToActive, currentOrder?.patient?.invoiceTo, setOrder]);

};
