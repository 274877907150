import React from 'react';
import { faBarcodeScan } from '@fortawesome/pro-light-svg-icons';
import { useApi } from 'providers';
import { ChooseWorkstationControl } from 'modules/orders/components/ChooseWorkstationControl';
import messages from 'messages';
import { OrderControlContext } from 'modules/orders/index.tsx';

const labels = messages.orders.controls.expendExtraDynamicBarcode;

export const ExpendAllDynamicBarcode = (props: OrderControlContext) => {

  const { print: { expendExtraBarcode } } = useApi();
  const { data: { id, orders }, context: { selectedOrder }, bindings: { reload } } = props;

  const order = orders[selectedOrder || 0];

  const orderId = order?.id || id;

  return (
    <ChooseWorkstationControl
      icon={faBarcodeScan}
      label={labels.label}
      notifications={labels.notifications}
      defaultSelected={order?.apid}
      aid={order?.doctor.aid}
      onDone={async (apid) => {
        await expendExtraBarcode({ aid: order?.doctor.aid, id: orderId, workstation: apid });
        reload();
      }}
    />
  );
};
