import React, { useCallback } from 'react';
import { Doctor } from 'interfaces/api';
import { ListItem, Loader, Modal, Select } from 'components';
import { ListLayout, ListLayoutContext } from 'containers';
import { SortGrouping } from 'interfaces';
import { useApi } from 'providers';
import { useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { find } from 'lodash';
import messages from 'messages';

const convertToListItem = (doctor: Doctor): ListItem => {
  return {
    title: doctor.displayName,
    id: 'doctor-' + doctor.aid + '-' + doctor.pid,
    subtitle: doctor.kennung,
    meta: doctor,
    groupByValue: doctor.doctorGroupOfficeName,
    fields: (doctor.lanr || doctor.bsnr)
      ? [{
        label: 'LANR',
        value: doctor.lanr,
      }, {
        label: 'BSNR',
        value: doctor.bsnr,
      }]
      : undefined,
  };
};

export const SelectOfficeDoctor = (props: { onSelect?: (officeDoctor: Doctor) => void }) => {

  const { readonly } = useOrdersContext();
  const { officeDoctor, setOfficeDoctor, officeDoctors } = useOfficeDoctorContext();

  const onChange = useCallback((pid: number) => {
    const officeDoctor = find(officeDoctors, { pid });
    setOfficeDoctor(officeDoctor);
    props.onSelect?.(officeDoctor);
  }, [setOfficeDoctor, officeDoctors]);

  if (!officeDoctor || !officeDoctors || !officeDoctors.length) {
    return null;
  }

  return (
    <Select
      disabled={readonly || officeDoctors.length === 1}
      value={officeDoctor.pid}
      onChange={onChange}
      popupMatchSelectWidth={false}
      options={officeDoctors.map(office => ({ value: office.pid, label: office.displayName }))}
    />
  );

};

export const OfficeDoctorModal = (props: { visible: boolean; onCancel: () => void; onSelect?: (officeDoctor: Doctor) => void }) => {

  const { doctorGroupOffice: { listDoctorsPerGroupOffices } } = useApi();
  const { setOfficeDoctor, officeDoctor, ambiguousAid, loading } = useOfficeDoctorContext();

  const aid = ambiguousAid === true ? undefined : ambiguousAid;

  return (
    <Modal
      open={!officeDoctor && props.visible && ambiguousAid !== undefined}
      footer={null}
      fullHeight={true}
      title={messages.orders.chooseDoctor}
      children={(
        <>
          <ListLayout
            context={{} as ListLayoutContext}
            search={{}}
            list={{
              request: ({ offset, query }) => listDoctorsPerGroupOffices({ aid, offset, query }),
              transformResponse: convertToListItem,
              condensed: true,
              onItemClick: (item) => {
                setOfficeDoctor(item.meta);
                props.onSelect?.(item.meta);
              },
              groupBy: SortGrouping.STRING,
            }}
          />
          {loading && <Loader overlay/>}
        </>
      )}
      onCancel={props.onCancel}
      destroyOnClose
    />
  );

};
