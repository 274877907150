import React, { useMemo } from 'react';
import { Feature, OrderWizardRequirement } from 'interfaces/api';
import styles from './styles.module.less';
import { Collapse, CollapsePanel, Container, Icon, List } from 'components';
import { useBasketContext } from 'modules/orders/containers/OrderWizard/providers';
import cx from 'classnames';
import { MetaInfo } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/MetaInfo';
import { FormAction } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/FormAction';
import { TagCloud } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/TagCloud';
import { PopOverAnalyses } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/Analyses';
import { PopOverHeader } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/Header';
import { BakOptions } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/BakOptions';
import { useIncompatibleRequirements, useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { find } from 'lodash';
import { isProfile, isSuperRequirement } from 'modules/orders/containers/OrderWizard/utils';
import { Diagnoses } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/Diagnoses';
import { useGuard } from 'containers';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { Color } from 'interfaces';
import { Translate, useTranslate } from 'providers';
import messages from 'messages';
import { InvoiceTo } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/InvoiceTo';
import { faPencil, faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { PatientDisplayName } from 'modules/orders/containers/OrderWizard/components/HeaderControls';
import { RequirementPopoverControlsMenu } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/ControlsMenu.tsx';
import { MissingRequirementOption } from 'modules/orders/interfaces.tsx';

const RequirementPopOverContent = (props: { requirement: OrderWizardRequirement; detail?: boolean; basket?: boolean }) => {

  const { requirement, detail, basket } = props;
  const { uniqRequirements, invoiceToActiveInPopover, getBasketCount, inBasketAsDuplicateRequirement, setPending } = useBasketContext();
  const { wizardSettings } = useOfficeDoctorContext();
  const { currentOrder, orders, isPoolMode } = useOrdersContext();

  const guard = useGuard();
  const translate = useTranslate();

  const basketRequirement = useMemo(() => find(uniqRequirements, { id: requirement.id }), [uniqRequirements]);

  const getIncompatibleRequirements = useIncompatibleRequirements();
  const incompatible = getIncompatibleRequirements(requirement);

  const duplicateRequirements = inBasketAsDuplicateRequirement(requirement);
  const isIncompatibleCostUnit = basketRequirement && !isPoolMode && currentOrder?.costUnit && basketRequirement.form?.costUnit !== currentOrder?.costUnit;

  return (
    <Container className={cx({ [styles.inBasket]: !!basketRequirement })}>

      {incompatible.length > 0 && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'border-bottom')}>
          <Icon icon={faExclamationCircle} color={Color.Yellow}/>
          <div>
            <b><Translate message={messages.orders.wizard.basket.incompatible.title}/></b><br/>
            <Translate message={messages.orders.wizard.basket.incompatible.content} values={{ shortName: requirement.shortName, incompatible: incompatible.join(', ') }}/>
          </div>
        </div>
      )}

      {isIncompatibleCostUnit && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'border-bottom')}>
          <Icon icon={faExclamationCircle} color={Color.Yellow}/>
          <div>
            <b><Translate message={messages.orders.wizard.basket.incompatibleCostUnit.title}/></b><br/>
            <Translate message={messages.orders.wizard.basket.incompatibleCostUnit.content} values={{ costUnit: currentOrder?.costUnit }}/>
          </div>
        </div>
      )}

      {duplicateRequirements.length > 0 && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'border-bottom')}>
          <Icon icon={faExclamationCircle} color={Color.Yellow}/>
          <div>
            <b><Translate message={messages.orders.wizard.basket.duplicateRequirements.title}/></b><br/>
            <span
              dangerouslySetInnerHTML={{
                __html: translate(
                  messages.orders.wizard.basket.duplicateRequirements.content,
                  { duplicate: duplicateRequirements[0].shortName },
                ),
              }}/>
          </div>
        </div>
      )}

      <BakOptions requirement={requirement} basketRequirement={basketRequirement}/>

      <MetaInfo readMore detail={detail} basket={basket} requirement={requirement}/>

      {requirement.dynamicMaterials?.length > 0 && (
        <List
          className={'basket-dynamic-materials'}
          items={requirement.dynamicMaterials.map((material, idx) => ({ id: idx, title: `#${idx + 1} ${material.text}`, icons: basket ? [faPencil] : undefined }))}
          onItemClick={basket ? () => setPending({ requirement, missing: MissingRequirementOption.DynamicMaterials }) : undefined}
        />
      )}

      {!!basketRequirement && (getBasketCount(requirement) !== orders.length) && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'border-bottom')}>
          <Icon icon={faUserGroup}/>
          <ul className={'comma-seperated'}>
            {orders.filter(o => o.requirements.map(r => r.id).includes(requirement.id)).map((o, idx) => (
              <li key={idx}><PatientDisplayName {...o.patient}/></li>
            ))}
          </ul>
        </div>
      )}

      <FormAction requirement={requirement} basketRequirement={basketRequirement}/>

      {basket && invoiceToActiveInPopover(requirement) && !isIncompatibleCostUnit && (
        <InvoiceTo requirement={requirement}/>
      )}

      {requirement.diagnoses?.length > 0 && guard({ feature: Feature.RequirementDiagnoses }, () => true) && !isIncompatibleCostUnit && (
        <Diagnoses requirement={requirement} basket={basket}/>
      )}

      {wizardSettings?.preferences?.orderWizardPopoverHideAnalyses || (
        <PopOverAnalyses requirement={requirement} basket={basket}/>
      )}

      <TagCloud requirement={requirement}/>
    </Container>
  );

};

export const RequirementPopOver = (props: { requirement: OrderWizardRequirement; detail?: boolean; basket?: boolean }) => {

  const { requirement, detail, basket } = props;
  const { wizardSettings } = useOfficeDoctorContext();

  const superRequirement = isSuperRequirement(requirement) ? find(wizardSettings.superRequirements, { entityId: requirement.entityId }) : null;

  const getContent = () => {

    if (isProfile(requirement)) {
      const profile = find(wizardSettings.profiles, { id: requirement.id });
      return profile && (
        <Collapse accordion expandIconPosition={'end'}>
          {(requirement.requirements || []).map(r => (
            <CollapsePanel
              key={r.id}
              header={<PopOverHeader requirement={r} profileId={profile.id} basket={basket}/>}
              extra={<RequirementPopoverControlsMenu requirement={r} profileId={profile.id}/>}
            >
              <RequirementPopOverContent requirement={r} detail={detail} basket={basket}/>
            </CollapsePanel>
          ))}
        </Collapse>
      );
    }

    if (isSuperRequirement(requirement)) {
      const superRequirement = find(wizardSettings.superRequirements, { entityId: requirement.entityId });
      return (
        <Collapse accordion expandIconPosition={'end'}>
          {(superRequirement.requirements || []).map(r => (
            <CollapsePanel
              key={r.id}
              header={<PopOverHeader requirement={r} superRequirementId={superRequirement.id} basket={basket}/>}
              extra={<RequirementPopoverControlsMenu requirement={r}/>}
            >
              <RequirementPopOverContent requirement={r} detail={detail} basket={basket}/>
            </CollapsePanel>
          ))}
        </Collapse>
      );
    }

    return <RequirementPopOverContent requirement={requirement} detail={detail} basket={basket}/>;

  };

  return (

    <Container>

      <PopOverHeader requirement={superRequirement || requirement} basket={basket} showControls/>

      <Container className={styles.body} scrollY>
        {getContent()}
      </Container>

    </Container>
  );
};
