import React from 'react';
import { Workstation, WorkstationStatus } from 'interfaces/api';
import { useApi, useApiCall } from 'providers';
import { groupBy, keys, map } from 'lodash';
import { Icon, Select, SelectProps } from 'components';
import { faDesktop, faDesktopSlash } from '@fortawesome/pro-regular-svg-icons';
import { Color, Message } from 'interfaces';
import messages from 'messages';

type Props = {
  aid?: number;
  workstations?: Workstation[];
  placeholder?: Message;
  labelWithId?: boolean;
} & SelectProps<any>;

export const WorkstationSelect = (props: Props) => {

  const { workstations: { listWorkstations } } = useApi();

  const { aid, workstations: initialWorkstations, labelWithId, ...rest } = props;

  const loadWorkstations = useApiCall(() => listWorkstations({ aid }));

  const workstations = initialWorkstations || loadWorkstations.data;

  const groupedWorkstations = workstations ? groupBy(workstations, 'status') : undefined;

  return (
    <Select
      {...rest}
      popupMatchSelectWidth={false}
      loading={loadWorkstations.loading}
      optionGroup={
        groupedWorkstations
          ? map(keys(groupedWorkstations).sort().reverse(), workstationStatus => ({
            // @ts-expect-error todo
            label: messages.orders.workstations.status[workstationStatus],
            options: groupedWorkstations[workstationStatus].map(workstation => ({
              value: workstation.id,
              children: (
                <>
                  <Icon icon={workstation.status === WorkstationStatus.Disconnected ? faDesktopSlash : faDesktop} color={Color.Gray}/>
                  {` ${workstation.name}` + (labelWithId ? ` (${workstation.id})` : '')}
                </>
              ),
            })),
          }))
          : undefined
      }
    />
  );

};
