import React, { useCallback, useMemo, useState } from 'react';
import { Redirect, Route, RouteProps, Switch, useLocation } from 'react-router';
import { Message } from 'interfaces';
import { GuardConfig, useGuard } from 'containers';
import { IconProps } from 'components/Icon';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Routes.less';
import { Container } from 'components/Container';
import { filter, flatMapDeep, flatten, omit, sortBy } from 'lodash';

export interface SubRoute extends Omit<RouteProps, 'children'> {
  title?: Message;
  path: string;
  icon?: IconProps;
  children?: SubRoute[];
  guard?: GuardConfig;
  navHidden?: boolean;
  navClassName?: any;
  noTopBar?: boolean;
}

export const isInstanceOfSubRoute = (object: any): object is SubRoute => {
  return object?.path !== undefined;
};

export class RouteArray extends Array<SubRoute> {

  constructor(items: SubRoute[]) {
    super(...filter(items));
  }

  flatten = (route?: SubRoute): SubRoute[] => {

    const routes = route ? route.children : this;

    return flatMapDeep(routes, (sub: SubRoute) => {
      return [omit(sub, 'children')].concat(this.flatten(sub)) as SubRoute[];
    });
  };

}

type RoutesProps = {
  routes: RouteArray;
};

export const useActiveRoute = (routes: RouteArray) => {

  const location = useLocation();

  const isActive = useCallback((route: SubRoute) => {
    return (location.pathname + '/').indexOf(route.path + '/') === 0;
  }, [location]);

  const getActive = useCallback(() => {
    return filter<SubRoute>(routes?.flatten(), (route: SubRoute) => isActive(route));
  }, [isActive]);

  return { isActive, getActive };
};

export const useGuardedRoutes = (routes: RouteArray) => {

  const guard = useGuard();

  const renderRoute = useCallback((config: SubRoute): any => {
    const { path, children, component, guard: guardConfig } = config;

    const guardedChildren = filter(children?.map(c => c.guard ? guard(c.guard, () => c) : c));
    const exact = guardedChildren?.length > 0;

    const render = config.render || (!component && guardedChildren?.length > 0 ? () => <Redirect to={guardedChildren[0].path}/> : undefined);

    const route = <Route key={path} {...{ exact, path, component, render }}/>;

    return [guardConfig ? guard(guardConfig, () => route) : route].concat(guardedChildren.map(renderRoute));
  }, [guard]);

  return useMemo(() => filter(flatten(routes.map(renderRoute))), [routes, renderRoute]);

};

export const Routes: React.FC<RoutesProps> = ({ routes }) => {

  const guardedRoute = useGuardedRoutes(routes);

  return (
    <Switch>
      {guardedRoute}
    </Switch>
  );

};

type AnimatedRoutesProps = RoutesProps & {
  animationClassName?: string;
};

export const AnimatedRoutes: React.FC<AnimatedRoutesProps> = ({ routes, animationClassName }) => {

  const location = useLocation();
  const state = location.state as { key: string | undefined };

  const guardedRoute = useGuardedRoutes(routes);
  const getActive = useActiveRoute(routes).getActive;

  const active = useMemo(() => sortBy(getActive(), r => r.path.split('/').length * -1)[0]?.path || '/', [location.pathname]);

  const [switchKey, setSwitchKey] = useState<string | undefined>(state?.key);

  if (state?.key && switchKey !== state?.key) {
    setSwitchKey(state.key);
  }

  return (
    <div className={'animation-wrapper'}>
      <TransitionGroup appear component={null}>
        <CSSTransition
          mountOnEnter
          classNames={animationClassName || 'app-route-transition'}
          key={active}
          timeout={300}
        >
          <Container className={'animation-container'}>
            <Switch location={location} key={switchKey}>
              {guardedRoute}
            </Switch>
          </Container>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );

};
