import { useCallback, useEffect } from 'react';
import { OrderForm, OrderRequirement, OrderWizardRequirement, RequirementAggregationType, RequirementType } from 'interfaces/api';
import { filter, find, map } from 'lodash';
import { useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { useBasketRequirements } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useBasketRequirements';

export const useCostUnitCheck = () => {

  const { wizardSettings } = useOfficeDoctorContext();
  const basketRequirements = useBasketRequirements();
  const { currentOrder, isPoolMode, setOrderProperties, setAllOrSelectedOrders, setLatestSelectedFormId } = useOrdersContext();

  // find the best matching form of the given requirement having given cost unit
  const bestMatchingForm = useCallback((requirement: OrderRequirement, costUnit: string): OrderForm => {
    const alternateFormId = find(requirement.alternateGroupFormCostUnits, c => c.costUnit === costUnit);

    if (alternateFormId) {
      return find(wizardSettings?.forms, f => f.id === alternateFormId.formId && f.isPool === isPoolMode);
    }

    const formIds = requirement.entityType === RequirementType.Microbiological && requirement.alternateMicrobiologicalFormCostUnits
      ? map(requirement.alternateMicrobiologicalFormCostUnits, a => a.formId)
      : (requirement as any).forms;

    const candidates = wizardSettings?.forms.filter(f => formIds.includes(f.id) && f.costUnit === costUnit && f.isPool === isPoolMode);

    const best = candidates.find(f => f.name === requirement.form?.name);

    return best || (candidates.length ? candidates[0] : undefined);
  }, [wizardSettings?.forms, isPoolMode]);

  // cost unit type checks
  useEffect(() => {
    if (basketRequirements.length > 0 && wizardSettings?.forms) {
      if (currentOrder?.costUnit === undefined) {
        // set cost unit type
        const found = find(wizardSettings?.forms, { id: basketRequirements[0].formId });
        if (found?.costUnit) {
          setOrderProperties({ costUnit: found.costUnit });
        }
      } else {
        // remove requirements with wrong cost unit
        setAllOrSelectedOrders(order => ({
          ...order,
          requirements: filter(order.requirements.map((r: OrderWizardRequirement) => {

            if (order.costUnit !== r.form?.costUnit) {

              if (r.profileId) {
                return undefined;
              }

              const replaceForm = bestMatchingForm(r, currentOrder?.costUnit);

              if (replaceForm) {
                setLatestSelectedFormId(replaceForm.id);

                r.form = replaceForm;
                r.formId = replaceForm.id;

                if (r.entityType === RequirementType.Microbiological) {
                  const { id, entityId } = find(r.alternateMicrobiologicalFormCostUnits, { formId: r.formId });
                  r.id = id;
                  r.entityId = entityId;
                  r.forms = [r.formId];
                  r.filters = r.filters.map(f => f.type === RequirementAggregationType.OrderForm ? { ...f, id: r.formId, name: replaceForm.name } : f);
                }
              }
            }

            return r;
          })),
        }));
      }
    }
  }, [basketRequirements.length, wizardSettings?.forms, currentOrder?.costUnit, bestMatchingForm]);

};
