import React from 'react';
import { ApiRequest } from 'containers';
import { useApi } from 'providers';
import { SelectProps } from 'components';
import { useOfficeDoctorContext } from 'modules/orders/providers';

type Props = {
  selectRenderer: (props: Partial<SelectProps<any>>) => React.ReactNode;
};

export const HvCodeSelect: React.FC<Props> = (props) => {

  const { orderWizardAustriaSettings: { getAustriaHvCodes } } = useApi();
  const { wizardSettings: { costUnits } } = useOfficeDoctorContext();

  return (
    <ApiRequest
      errorAsAlert
      request={() => getAustriaHvCodes()}
      children={({ data }) => props.selectRenderer({
        options: data.filter(h => costUnits.includes(h.costUnit)).map(hvcode => ({
          label: hvcode.costUnit + ' - ' + hvcode.title,
          value: hvcode.code,
        })),
      })}
    />
  );

};
