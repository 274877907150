import { DatePickerProps as AntDatePickerProps } from 'antd/es/date-picker';
import './DatePicker.less';
import { DatePicker as AntDatePicker, InputRef } from 'antd';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import imask from 'imask';
import { IMaskInput } from 'react-imask';
import { getLongDateFormat } from 'utils/helpers';
import messages from 'messages';
import { useTranslate } from 'providers';

export type DatePickerProps = AntDatePickerProps & {
  disablePast?: boolean;
  disableFuture?: boolean;
  format?: string;
};

type MaskedInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  format?: string;
};

const MaskedInput = (format: string = 'L') => forwardRef<InputRef, MaskedInputProps>((props, ref) => {

  const translate = useTranslate();

  const [isFocused, setIsFocused] = useState(props.autoFocus);

  const dateFormat = getLongDateFormat();
  const maskPattern = useMemo(() => format.replace('LT', 'HH:mm').replace('L', dateFormat), [dateFormat]);

  const inputRef = useRef(null);

  useImperativeHandle(ref, () => {
    return inputRef.current.element;
  }, []);

  const { blocks: blocksLabels } = messages.general.dateFormat;

  return (
    <IMaskInput
      mask={Date}
      ref={inputRef}
      pattern={maskPattern}
      format={(date: any) => dayjs(date).format(maskPattern)}
      parse={str => dayjs(str, maskPattern) as any}
      value={props.value + ''}
      inputMode={'numeric'}
      placeholder={props.placeholder}
      unmask={false}
      lazy={!isFocused}
      onComplete={(value: string) => {
        if (props.value !== value) {
          props.onChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
          props.onBlur?.(undefined);
        }
        inputRef.current?.blur?.();
      }}
      onMouseDown={props.onMouseDown}
      onFocus={(event) => {
        setIsFocused(true);
        props.onFocus?.(event as any);
        props.onFocus?.(event as any);
      }}
      onBlur={(event) => {
        setIsFocused(false);
        props.onBlur?.(event as any);
        props.onBlur?.(event as any);
      }}
      blocks={{
        DD: { mask: imask.MaskedRange, placeholderChar: translate(blocksLabels.D), from: 1, to: 31, maxLength: 2 },
        MM: { mask: imask.MaskedRange, placeholderChar: translate(blocksLabels.M), from: 1, to: 12, maxLength: 2 },
        YYYY: { mask: imask.MaskedRange, placeholderChar: translate(blocksLabels.Y), from: 1900, to: 2999, maxLength: 4 },
        HH: { mask: imask.MaskedRange, placeholderChar: translate(blocksLabels.h), from: 0, to: 24, maxLength: 2 },
        mm: { mask: imask.MaskedRange, placeholderChar: translate(blocksLabels.m), from: 0, to: 59, maxLength: 2 },
      } as any}
    />
  );
});

export const DatePicker: React.FC<DatePickerProps> = (props) => {

  const { disablePast, disableFuture, ...rest } = props;

  const [value, setValue] = useState(props.value ? dayjs(props.value) : undefined);

  useEffect(() => setValue(props.value ? dayjs(props.value) : undefined), [props.value]);

  const disabledDateFn = useCallback((current: dayjs.Dayjs) => {
    if (disableFuture && current && current > dayjs().endOf('day')) {
      return true;
    } else if (disablePast && current && current < dayjs().startOf('day')) {
      return true;
    }
    return false;
  }, [disableFuture, disablePast]);

  const format = useMemo(() => props.format || 'L', [props.format]);

  const onChange = (newValue: dayjs.Dayjs, newValueString: string) => {
    props.onChange(newValue, newValueString);
    setValue(newValue);
  };

  return (
    <AntDatePicker
      {...rest}
      format={format}
      preserveInvalidOnBlur
      disabledDate={props.disabledDate || disabledDateFn}
      components={{ input: MaskedInput(format) }}
      value={value}
      onChange={onChange}
    />
  );
};
