import React from 'react';
import { OrderRequirementsFilter, OrderRequirementsSpecialFilterId, OrderWizardRequirement, RequirementAggregationType } from 'interfaces/api';
import styles from './styles.module.less';
import { Container, Icon, Tag } from 'components';
import { useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { filter, find, map } from 'lodash';
import { Translate } from 'providers';
import { useRequirementFilteredForms } from 'modules/orders/containers/OrderWizard/providers';

export const TagCloud = (props: {
  requirement: OrderWizardRequirement;
}) => {

  const { requirement } = props;

  const { params: { getFilterConfig, toggleFilter } } = useOrdersContext();
  const { availableFilters, wizardSettings } = useOfficeDoctorContext();
  const filterForms = useRequirementFilteredForms();

  const allFilters: OrderRequirementsFilter[] = filter(map([
    ...filterForms(requirement).map<OrderRequirementsFilter>(id => ({ id, type: RequirementAggregationType.OrderForm })),
    ...(requirement.filters || []),
  ], f => f.id !== OrderRequirementsSpecialFilterId.Unassigned && find(availableFilters, f)));

  if (allFilters.length === 0) {
    return null;
  }

  return (
    <Container className={styles.footer}>
      <div
        style={{
          '--oa-bak-color': '#' + wizardSettings?.preferences?.orderWizardBakColor,
          '--oa-bak-font-color': '#' + wizardSettings?.preferences?.orderWizardBakFontColor,
        } as React.CSSProperties}
      >

        {allFilters.map((filter, idx) => {

          const config = getFilterConfig(filter);
          return (
            <Tag key={idx} color={config.color} className={config.className}>
              <a onClick={() => toggleFilter(filter)}>
                <Icon {...config.icon}/><Translate message={config.name}/>
              </a>
            </Tag>
          );
        })}
      </div>
    </Container>
  );

};
