import React from 'react';
import { Control, Form, HelgaContainer, HelgaImageType } from 'components';
import messages from 'messages';
import { useOrderRulesContext } from 'modules/orders/providers';
import { filter, fromPairs, get, groupBy, map } from 'lodash';
import { FormLayout } from 'containers';
import { WriteableOrderPropertiesSchema } from 'interfaces/api';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useInvalidFields } from 'modules/orders/providers/OrderRulesProvider/useInvalidFields.tsx';
import { useTranslate } from 'providers';

export const InvalidFields = () => {

  const { invalidFields, updateValues } = useInvalidFields();
  const { cancel, loading, pending } = useOrderRulesContext();

  const translate = useTranslate();

  if (!invalidFields) {
    return null;
  }

  return (
    <Form
      suppressControls
      disabled={loading}
      initialValue={fromPairs(invalidFields.map(m => [m.index + '.' + m.field, m.value]))}
      request={values => updateValues(invalidFields.map((m) => {
        return ({ ...m, value: values[m.index + '.' + m.field] });
      }))}
    >
      {({ submit, Input, value }) => (
        <HelgaContainer
          image={HelgaImageType.Report}
          title={messages.orders.missing}
          buttons={[(
            <Control
              label={messages.general.cancel}
              onClick={cancel}
              button={{}}
            />
          ), (
            <Control
              icon={faArrowRight}
              label={messages.general.next}
              onClick={submit}
              disabled={filter(value, v => v === undefined || v === null || !(value + '').length).length > 0}
              button={{
                loading,
                type: 'primary',
              }}
            />
          )]}
        >

          {map(groupBy(invalidFields, 'index'), (fields, index) => (

            // eslint-disable-next-line id-denylist
            <FormLayout key={index} embedded narrowLabels label={pending.orders.length > 1 ? translate(messages.orders.orderNumber, { number: parseInt(index) + 1 }) : ''}>

              {fields.map((m) => {

                const property = m.index + '.' + m.field;
                const label = get(messages.orders.fields, m.field)
                  || get(messages.orders.additionalFields, m.field)
                  || get(messages.orders.additionalFields.patient, m.field) || m.field;

                return (
                  <Input
                    key={property}
                    property={property}
                    label={label}
                    error={translate((messages.orders.fieldErrors as any)[m.field]) || m.message}
                    maxLength={(get(WriteableOrderPropertiesSchema, m.field) as any)?.maxLength}
                  />
                );

              })}

            </FormLayout>

          ))}

        </HelgaContainer>
      )}
    </Form>
  );

};
