import React from 'react';
import { CollapsePanelProps as AntCollapsePanelProps, default as AntCollapse } from 'antd/es/collapse';

type OverwriteProps = object;

export type CollapsePanelProps = Omit<AntCollapsePanelProps, keyof OverwriteProps> & OverwriteProps;

export const CollapsePanel: React.FC<CollapsePanelProps> = props => (
  <AntCollapse.Panel {...props}/>
);
