import { ErrorBoundaryHelga } from './ErrorBoundaryHelga';
import React from 'react';
import { ErrorBoundary } from '@sentry/react';

export type SentryErrorBoundaryProps = {
  children: React.ReactNode;
};

export const SentryErrorBoundary = (props: SentryErrorBoundaryProps) => {
  const { children } = props;
  return (
    <ErrorBoundary fallback={errorData => <ErrorBoundaryHelga error={errorData.error}/>}>
      {children}
    </ErrorBoundary>
  );
};
