import { hexLuma, rgbFromHex } from 'utils/helpers';
import { getCssVariableValue } from 'utils/dom.ts';

export const enum Color {
  Alert = 'alert',
  Red = 'red',
  Green = 'green',
  Yellow = 'yellow',
  Blue = 'blue',
  Gray = 'gray',
  Black = 'black',
  DarkGray = 'dark-gray',
  LightGray = 'light-gray',
  Silver = 'silver',
  Smoke = 'smoke',
  Purple = 'purple',
  Orchid = 'orchid',
  Magenta = 'magenta',
  Brown = 'brown',
  Cyan = 'cyan',
  SkyBlue = 'sky-blue',
  SteelBlue = 'steel-blue',
  KashmirBlue = 'kashmir-blue',
  SeeGreen = 'see-green',
  Turquoise = 'turquoise',
  Salmon = 'salmon',
  Beige = 'beige',
  Bone = 'bone',
  White = 'white',
  GreenSpring = 'green-spring',
  Bak = 'bak',
}

export interface CssColor {
  getCssColor: () => string;
}

export class CssVariableColor implements CssColor {

  constructor(public variable: string) {
  }

  getCssColor(): string {
    return getCssVariableValue(this.variable);
  }

}

export class RgbColor implements CssColor {

  constructor(public r: number, public g: number, public b: number) {
  }

  getCssColor(): string {
    return `#${this.r.toString(16).padStart(2, '0')}${this.g.toString(16).padStart(2, '0')}${this.b.toString(16).padStart(2, '0')}`;
  }

}

export class HexColor implements CssColor {

  constructor(public value: string) {
  }

  toRgb() {
    return rgbFromHex(this.value);
  }

  getLuma() {
    return hexLuma(this.value);
  }

  getCssColor(): string {
    return (!this.value?.startsWith('#') ? '#' : '') + this.value;
  }

}

export const isCssColor = (value: any): value is CssColor => {
  return typeof value?.getCssColor === 'function';
};
