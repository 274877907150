import React, { useMemo } from 'react';
import * as styles from './CourierDialog.less';
import { Button, Container, Control, Form, Icon, Popover } from 'components';
import cx from 'classnames';
import { faTruck } from '@fortawesome/pro-regular-svg-icons';
import { Translate, useApi, useTranslate } from 'providers';
import { useToggle } from 'hooks';
import { MessageCenterCreateMessageThreadRequest, MessageThreadType } from 'interfaces/api';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import dayjs from 'dayjs';
import messages from 'messages';

export const CourierDialog = () => {

  const { messageCenter: { createMessageThread } } = useApi();
  const translate = useTranslate();

  const [showCourierDialog, toggleShowCourierDialog] = useToggle(false);

  const labels = messages.general.topBar.delivery;
  const today = new Date();
  const now = today.getTime();

  const popoverContent = useMemo(() => (
    <Container grow onClick={e => e.stopPropagation()}>

      <Container padding className={cx(styles.deliveryPopover)}>

        <Container horizontal>
          <span className={cx(styles.popoverTitle)}><Translate message={labels.form.title}/></span>
          <div className={styles.closeControl}>
            <Control icon={faTimes} onClick={() => toggleShowCourierDialog()}/>
          </div>
        </Container>

        <Form
          resetOnSuccess
          suppressControls
          initialValue={{} as MessageCenterCreateMessageThreadRequest}
          request={(value) => {
            return createMessageThread(({
              type: MessageThreadType.CourierService,
              subject: translate(labels.form.subject),
              receiver_id: 1,
              appointmentDate: dayjs(value.appointmentDate || today).format('YYYY-MM-DD'),
              appointmentTime: dayjs(value.appointmentTime || now).format('HH:mm:ss'),
              appointmentNote: value.appointmentNote || '',
            }));
          }}
          onSubmit={() => toggleShowCourierDialog()}
          notifications={{
            success: labels.form.success,
            error: labels.form.error,
          }}
        >
          {({ Input, DatePicker, TimePicker }) => (
            <>
              <DatePicker property={'appointmentDate'} placeholder={translate(labels.form.date)} disablePast/>
              <TimePicker property={'appointmentTime'} placeholder={translate(labels.form.time)} minuteStep={15} format={'HH:mm'}/>
              <Input property={'appointmentNote'} placeholder={translate(labels.form.comment)}/>
              <Button type="primary" htmlType="submit" className={cx(styles.popoverButton)}>
                <Icon icon={faTruck} className={cx(styles.popoverButtonIcon)}/> <Translate message={labels.form.button}/>
              </Button>
            </>
          )}
        </Form>
      </Container>

    </Container>
  ), []);

  return (
    <Popover content={popoverContent} open={showCourierDialog} trigger="click" onOpenChange={toggleShowCourierDialog}>
      <Control isActive={showCourierDialog} icon={faTruck} onClick={() => toggleShowCourierDialog()}/>
    </Popover>
  );
};
