import React from 'react';
import { ModalFormControl } from 'components';
import { useApi } from 'providers';
import { ListLayoutArgs, ListLayoutSideBarButtonCallback } from 'containers';
import messages from 'messages';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { useOfficeDoctorContext } from 'modules/orders/providers';
import { OrdersWizardForm } from 'modules/orders/components/Forms';
import { UserType } from 'interfaces/api';

type Props = ListLayoutArgs<any>;

const labels = messages.orders.wizard.sideBar.settings;

const Component = React.memo((props: Props) => {

  const { orderSettings: { updateUserOrdersConfig } } = useApi();

  const { officeDoctor: { aid }, wizardSettings: { preferences }, reloadWizardSettings } = useOfficeDoctorContext();

  return (
    <ModalFormControl
      icon={faCog}
      label={labels.title}
      modal={{
        scrollY: true,
      }}
      form={{
        noShrink: true,
        initialValue: { preferences },
        request: values => updateUserOrdersConfig({
          entityId: aid,
          type: UserType.ARZ,
          preferences: values.preferences,
        }),
        onSuccess: async (values, response) => {
          await reloadWizardSettings(aid);
          props.bindings.reload();
        },
        children: ({ Property }) => (
          <Property property={'preferences'}>
            {args => <OrdersWizardForm aid={aid} {...args}/>}
          </Property>
        ),
      }}
    />
  );

});

export const OrderWizardSettings: ListLayoutSideBarButtonCallback<void> = () => ({
  component: Component,
});
