import React from 'react';
import { useApi } from 'providers';
import { OrderControlContext } from 'modules/orders/index';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useOfficeDoctorContext, useOrdersContext, useWizardToggleContext } from 'modules/orders/providers';
import { flatten, map, uniq } from 'lodash';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';

const labels = messages.orders.controls.reRequest;

export const RealReRequest = (props: OrderControlContext) => {

  const { data: { doctor, orders, hasMissingAggregation }, context: { selectedOrder } } = props;

  const { setOfficeDoctor } = useOfficeDoctorContext();
  const { resetOrders, setImmutableRequirementIds } = useOrdersContext();
  const toggle = useWizardToggleContext();

  const { realReRequest: { startRealReRequest } } = useApi();

  return (
    <ChooseOrdersControl
      icon={faPlusCircle}
      button={{
        type: 'primary',
        block: true,
      }}
      single
      defaultSelectedOrder={selectedOrder}
      disabledCheck={o => !o.realReRequestConditions?.allowed}
      disabled={hasMissingAggregation}
      orders={orders}
      label={labels.label}
      onDone={async (orders) => {
        const { aid } = doctor;
        const { lanr, bsnr } = orders[0];

        await setOfficeDoctor({ aid, lanr, bsnr });

        const groupedOrders = await startRealReRequest({ orderIds: map(orders, o => o.id) });
        resetOrders(groupedOrders);

        setImmutableRequirementIds(uniq(map(flatten(map(groupedOrders, no => no.requirements)), r => r.id)));

        toggle[1](true);
      }}
    />
  );
};
