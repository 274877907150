import { useApi, useBiometrics, useIntlStoreSelectors, useStorage } from 'providers';
import { useErrorModal } from 'components';
import { useCallback } from 'react';
import messages from 'messages';
import { useAuthStoreSelectors } from 'modules/auth/store';

export const useAutoLogin = () => {

  const user = useAuthStoreSelectors.user();
  const setUser = useAuthStoreSelectors.setUser();
  const setLoadingAutoLogin = useAuthStoreSelectors.setLoadingAutoLogin();

  const biometrics = useBiometrics();
  const translate = useIntlStoreSelectors.translate();

  const { authentication: { exchangeToken } } = useApi();

  const errorModal = useErrorModal();

  const { getToken } = useStorage();

  return useCallback(async () => {

    if (!user) {

      const deviceToken = await getToken();

      if (deviceToken) {
        await biometrics.show({});
        setLoadingAutoLogin(true);
        try {
          setUser(await exchangeToken({ token: deviceToken }));
        } catch (e) {
          biometrics.disable();
          errorModal({
            title: translate(messages.auth.login.autoLoginError.title),
            content: translate(messages.auth.login.autoLoginError.content),
          });
        }
        setLoadingAutoLogin(false);
      }
    }
  }, [getToken, biometrics.show, biometrics.disable, exchangeToken, setLoadingAutoLogin]);
};
