import { NotificationMethod, NotificationType } from 'interfaces/api';

export default {
  general: {
    settings: {
      disableAll: 'All Notifications',
    },
  },
  read: 'Set read',
  dismiss: 'Dismiss',
  dismissAll: 'Set all as read',
  warning: 'This configuration will not trigger any notifications.',
  enableFor: 'Enable notifications for {title}',
  enableDescription: 'Shown in notification center',
  enablePush: {
    title: 'Push notification',
    description: 'Push to all your registered devices',
  },
  enableMail: {
    title: 'E-Mail notification',
    description: 'Get an E-Mail for this notification',
  },
  notificationMethods: {
    [NotificationMethod.Push]: 'Push',
    [NotificationMethod.Mail]: 'Mail',
    [NotificationMethod.Sms]: 'SMS',
  },
  notificationTypes: {
    [NotificationType.NewExchangeFile]: 'New Exchange File',
    [NotificationType.NewReport]: 'New Report',
    [NotificationType.NewMessage]: 'New Message',
  },

};
