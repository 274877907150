import { useCallback, useState } from 'react';

export type Toggle = [boolean, (nextValue?: boolean) => void];

export const useToggle = (initialValue: boolean): Toggle => {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggle = useCallback(
    (nextValue?: boolean) => {
      if (nextValue !== undefined) {
        setValue(nextValue);
      } else {
        setValue(currentValue => !currentValue);
      }
    },
    [],
  );

  return [value, toggle];
};
