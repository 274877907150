import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Control, FormItem, HelgaContainer, Icon, Input, Select } from 'components';
import { faArrowRight, faArrowRightFromLine } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { toggleArrayItem } from 'utils/helpers';
import { useAnamnesisContext, useOrdersConfig } from 'modules/orders/providers';
import { filter, find, flatten, groupBy, map, range, sortBy, toPairs } from 'lodash';
import { AnamnesisQuestion, AnamnesisQuestionType, Order, OrderAnamnesis } from 'interfaces/api';
import cx from 'classnames';
import './AnamnesisForm.less';
import { FormLayout } from 'containers';
import { Message } from 'interfaces';
import { Col, Row } from 'antd';

type Props = {
  order: Order;
  questions: AnamnesisQuestion[];
  title?: Message;
  cancel?: () => void;
  next?: (answers: OrderAnamnesis[]) => void;
  nextLabel?: Message;
  loading?: boolean;
  disabled?: boolean;
  onChange?: (answers: OrderAnamnesis[]) => void;
};

export const AnamnesisForm: React.FC<Props> = ({ cancel, next, questions, nextLabel, loading, disabled, order, onChange, title }) => {

  const { preferences: { ordersAnamnesisShrinkMode } } = useOrdersConfig();
  const { getMetaForQuestion, getMissingPreviousAnswers } = useAnamnesisContext();

  const [answers, setAnswers] = useState<OrderAnamnesis[]>(order?.anamnesis || []);

  useEffect(() => onChange?.(answers), [answers]);

  const setAnswersWithDefault = useCallback((newAnswers: OrderAnamnesis[]) => {
    setAnswers(newAnswers);
    getMissingPreviousAnswers(newAnswers, withMissing => setAnswers(withMissing));
  }, [answers]);

  const setAnswer = useCallback((answer: OrderAnamnesis) => {
    setAnswersWithDefault([...answers.filter(a => a.questionId !== answer.questionId), answer]);
  }, [answers, setAnswersWithDefault]);

  const toggleAnswer = useCallback((answer: OrderAnamnesis) => {
    setAnswersWithDefault(toggleArrayItem(answers, answer, a => a.answerId));
  }, [answers, setAnswersWithDefault]);

  const renderGroupedQuestions = (questions: AnamnesisQuestion[], level = 0, parent: number = null) => {
    return map(sortBy(toPairs(groupBy(questions, q => q.header?.id || 0)), q => q[1][0].header?.weight || 0), ([header, qs]) => (
      <FormLayout
        key={header}
        label={qs[0]?.header?.text || ''}
        style={{
          backgroundColor: qs[0]?.header?.backgroundColor ? `#${qs[0]?.header?.backgroundColor}` : undefined,
        }}
        embedded
        className={cx({ 'anamnesis-horizontal': qs[0]?.header?.horizontal })}
      >
        <Row gutter={qs[0]?.header?.horizontal ? [12, 12] : 0}>
          {sortBy(qs, 'weight').map((question) => {

            const triggerAnswers = flatten((question.answers || []).filter(answer => find(
              answers,
              a => a.questionId === question.id && a.answerId === answer.id && answer.questions?.length > 0,
            )).map(a => a.questions));

            const indentation = (
              <span className={'anamnesis-indentation'}>
                {range(0, level).map(idx => <Icon icon={faArrowRightFromLine} key={idx}/>)}
              </span>
            );

            const label = qs[0]?.header?.horizontal
              ? <span>{question.text}</span>
              : (
                <>
                  {indentation}
                  <span>{question.text}</span>
                </>
              );

            if (question.type === AnamnesisQuestionType.Infotext) {
              return (
                <Col key={question.id} span={24}>
                  <Container className={'anamnesis-info-text'}>
                    <p style={question.infoTextStyle ? JSON.parse(question.infoTextStyle) : undefined} dangerouslySetInnerHTML={{ __html: question.infoText }}/>
                  </Container>
                </Col>
              );
            }

            return (
              <Col key={question.id} span={question.field?.gridSize || 24}>
                <FormItem
                  label={label}
                  className={cx({ 'anamnesis-question-mandatory': question.mandatory, 'anamnesis-question-buttons': !question.field })}
                  style={{ backgroundColor: question.backgroundColor ? `#${question.backgroundColor}` : undefined }}
                  floating={qs[0]?.header?.horizontal}
                >
                  {renderQuestion(question, parent)}
                </FormItem>
                {renderGroupedQuestions(triggerAnswers, level + 1, question.id)}
              </Col>
            );
          })}
        </Row>
      </FormLayout>
    ));
  };

  const renderQuestion = (question: AnamnesisQuestion, parent: number = null) => {

    const orderAnswer: OrderAnamnesis = {
      parentQuestionId: parent,
      ...getMetaForQuestion(question),
    };

    if (question.field) {

      const fieldValue = find(answers, a => a.questionId === question.id)?.fieldValue;
      const setFieldValue = (fieldValue: string) => setAnswer({ ...orderAnswer, fieldValue, fieldId: question.field.id });

      if (question.field.complist?.length) {
        return (
          <Select
            value={fieldValue}
            onChange={e => setFieldValue(e)}
            placeholder={question.field.placeholder}
            options={question.field.complist.map(c => ({ value: c, label: c }))}
            disabled={disabled}
          />
        );
      }

      return (
        <Input
          value={fieldValue}
          onChange={e => setFieldValue(e.target.value)}
          placeholder={question.field.placeholder}
          maxLength={question.field.maxlength}
          disabled={disabled}
        />
      );
    } else {

      const onClickFn = (question.type === AnamnesisQuestionType.Checkbox ? toggleAnswer : setAnswer);

      return (
        <ul>
          {(question.answers || []).map((answer, idx) => {
            return (
              <li key={idx}>
                <Button
                  className={cx({ ['button-selected']: !!find(answers, { questionId: question.id, answerId: answer.id }) })}
                  disabled={disabled}
                  onClick={() => onClickFn({
                    ...orderAnswer,
                    answerId: answer.id,
                    answerText: answer.text,
                  })}
                >
                  {answer.text}
                </Button>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <HelgaContainer
      title={title !== null ? title : messages.orders.anamnesis.title}
      className={cx('anamnesis-form-container', { 'anamnesis-shrink-mode': ordersAnamnesisShrinkMode })}
      buttons={filter([cancel && (
        <Control
          label={messages.general.cancel}
          onClick={cancel}
          button={{}}
          disabled={loading}
        />
      ), next && (
        <Control
          icon={faArrowRight}
          label={nextLabel}
          onClick={() => next(answers)}
          button={{
            loading,
            type: 'primary',
          }}
        />
      )])}
    >
      {renderGroupedQuestions(questions)}
    </HelgaContainer>
  );
};
