import React, { useMemo } from 'react';
import { Control, ControlProps } from 'components/Control';
import { faTag } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useOfficeDoctorContext, useOrdersConfig, useOrdersContext, useWizardToggleContext } from 'modules/orders/providers';
import { WriteableOrderProperties } from 'interfaces/api';

export type NewOrderControlProps = Partial<ControlProps> & {
  initialOrders?: WriteableOrderProperties[];
  aid?: number;
};

export const NewOrder: React.FC<NewOrderControlProps> = ({ initialOrders, aid, ...controlProps }) => {

  const toggle = useWizardToggleContext();
  const { preferences: { orderWizardNewOrderShowSelectPatient } } = useOrdersConfig();

  const { setOfficeDoctor } = useOfficeDoctorContext();
  const { resetOrders, setImmutableRequirementIds, setShowPatientSelectModal } = useOrdersContext();

  const onClick = () => {
    setOfficeDoctor(aid > 0 ? { aid } : undefined);
    resetOrders(initialOrders);
    setImmutableRequirementIds([]);
    toggle[1](true);
    if (orderWizardNewOrderShowSelectPatient) {
      setShowPatientSelectModal(true);
    }
  };

  return useMemo(() => (
    <Control
      icon={faTag}
      label={messages.orders.create}
      button={{
        type: 'primary',
      }}
      onClick={onClick}
      {...controlProps}
    />
  ), [onClick]);
};
