import React from 'react';
import { ReportValue } from 'interfaces/api';
import { Container } from 'components';
import dayjs from 'dayjs';
import './Chart.less';
import cx from 'classnames';
import { Color } from 'interfaces';
import Highcharts, { Tooltip } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { getCssColor, getCssVariableValue } from 'utils/dom';
import { flatMap, max, min, tail } from 'lodash';

HighchartsMore(Highcharts);

interface Props {
  values: ReportValue[][];
  selected?: string;
  className?: string;
  compact?: boolean;
  weightedMode?: boolean;
}

interface State {
  selected?: string;
}

const dateTimeLabelFormats = {
  millisecond: '%H:%M:%S.%L',
  second: '%H:%M:%S',
  minute: '%H:%M',
  hour: '%H:%M',
  day: '%d.%m.%Y',
  week: '%d.%m.%Y',
  month: '%d.%m.%Y',
  year: '%Y',
};

export const ChartColors: Color[] = [
  Color.Blue,
  Color.Green,
  Color.Red,
  Color.SteelBlue,
  Color.Turquoise,
  Color.Magenta,
  Color.Purple,
];

const formatTooltipHeader = (date: string): string => {
  return `<span style="font-size: 10px"><b>${date}</b></span><br/>`;
};

class Chart extends React.Component<Props, State> {

  categories: dayjs.Dayjs[] = [...new Set<dayjs.Dayjs>(flatMap(this.props.values, report => report.map(result => dayjs(result.date))))];

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  // static function is needed to access highcharts' this.stuff
  static formatAdjustedModeTooltip(tooltip: Tooltip) {

    const highchartsThis: any = this;

    const category: dayjs.Dayjs = highchartsThis.key;
    const superTooltip = tooltip.defaultFormatter.call(highchartsThis, tooltip);

    return [formatTooltipHeader(category?.format('DD.MM.YYYY HH:mm')), ...tail(superTooltip)];
  }

  getCategoryIndex = (value: ReportValue) => {
    return this.categories.map(category => category.valueOf()).indexOf(dayjs(value.date).valueOf());
  };

  createSeries = (values: ReportValue[]) => {

    const getValueColor = (value: ReportValue) => {

      const from = parseFloat(value.reference.from);
      const to = parseFloat(value.reference.to);
      const result = parseFloat(value.result);

      if (!isNaN(from) && result < from) {
        return Color.Red;
      }

      if (!isNaN(to) && result > to) {
        return Color.Red;
      }

      return Color.Green;
    };

    // see https://api.highcharts.com/highcharts/series.line.data
    const data = values.filter(value => !isNaN(parseFloat(value.result))).map((value, index) => ({
      x: this.props.weightedMode ? dayjs(value.date).valueOf() : this.getCategoryIndex(value),
      y: parseFloat(value.result),
      color: getCssColor(getValueColor(value)),
    }));

    return {
      data,
      name: values[0].description,
      showInLegend: false,
      lineWidth: 1,
      tooltip: {
        dateTimeLabelFormats,
        headerFormat: formatTooltipHeader('{point.key}'),
        valueSuffix: ' ' + values[0].unit,
        xDateFormat: '%d.%m.%Y %H:%M',
      },
      marker: {
        symbol: 'circle',
        radius: 6,
        enabled: true,
        enabledThreshold: 50,
      },
    };
  };

  render() {

    const { values, className } = this.props;

    const config: any = {

      colors: ChartColors.map(c => getCssColor(c)),

      chart: {
        type: 'line',
        zoomType: 'x',
        backgroundColor: 'transparent',
        style: {
          fontFamily: getCssVariableValue('--font-family'),
        },
        spacing: [0, 5, 0, 5],
        plotBorderColor: getCssVariableValue('--background-color-dark'),
      },

      series: values.map(this.createSeries),

      title: {
        text: null as any,
      },

      credits: {
        enabled: false,
      },

      plotOptions: {
        series: {
          pointRange: 60 * 1000 * 60 * 24,
          pointInterval: 24 * 3600 * 1000,
          dataGrouping: { enabled: false },
        },
        arearange: {
          fillOpacity: 1,
        },
      },

      // see https://api.highcharts.com/highcharts/xAxis
      xAxis: {
        dateTimeLabelFormats,
        type: this.props.weightedMode ? 'datetime' : 'category',
        categories: this.props.weightedMode ? null : this.categories,
        labels: {
          style: {
            color: getCssColor(Color.Black),
          },
          formatter: this.props.weightedMode
            ? null
            : (category: any) => {
              const { value } = category;
              return value && value.format && value.format('DD.MM.YYYY HH:mm');
            },
        },
        minPadding: 0,
        maxPadding: 0,
        min: this.props.weightedMode ? null : 0.5,
        max: this.props.weightedMode ? null : this.categories.length - 1.5,
      },

      yAxis: {
        title: {
          enabled: false,
        },
        gridLineColor: getCssColor(Color.LightGray),
        endOnTick: false,
        startOnTick: false,
        labels: {
          style: {
            color: getCssColor(Color.Black),
          },
        },
        minPadding: 0,
        maxPadding: 0,
      },

      loading: false,

      tooltip: {
        formatter: this.props.weightedMode ? null : Chart.formatAdjustedModeTooltip,
      },

    };

    if (this.props.compact) {
      config.chart.margin = [0, 0, 0, 0];
      config.yAxis.gridLineColor = false;
    }

    // Display red areas for too high/low values
    if (values.length === 1) {

      const minimum = min(values[0].map(value => parseFloat(value.result)));
      const maximum = max(values[0].map(value => parseFloat(value.result)));

      const minVon = min(values[0].map(value => parseFloat(value.reference.from)));
      const maxBis = max(values[0].map(value => parseFloat(value.reference.to)));

      const generatePlot = (generator: (value: ReportValue) => any) => ({
        type: 'arearange',
        color: getCssColor(Color.Red),
        showInLegend: false,
        enableMouseTracking: false,
        animation: { duration: 0 },
        data: values[0].map(generator),
        lineWidth: 0,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      });

      if (!isNaN(minVon)) {
        const bottom = minimum - max([maximum - minimum, maximum - minVon]);
        config.yAxis.min = bottom;
        config.series.unshift(generatePlot(value => ({
          x: this.props.weightedMode ? dayjs(value.date).valueOf() : this.getCategoryIndex(value),
          low: bottom,
          high: parseFloat(value.reference.from),
        })));
      }

      if (!isNaN(maxBis)) {
        const top = maximum + max([maximum - minimum, maxBis - minimum]);
        config.yAxis.max = top;
        config.series.unshift(generatePlot(value => ({
          x: this.props.weightedMode ? dayjs(value.date).valueOf() : this.getCategoryIndex(value),
          low: parseFloat(value.reference.to),
          high: top,
        })));
      }
    }

    return (
      <Container grow className={cx('report-chart', className)}>
        <HighchartsReact highcharts={Highcharts} options={config} style={{ width: '100%', height: '100%' }}/>
      </Container>
    );

  }

}

export default Chart;
