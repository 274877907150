import { useCallback } from 'react';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useApi, useMaintenanceProvider, useNetworkProvider } from 'providers';

export const useReloadUserDetails = () => {

  const user = useAuthStoreSelectors.user();
  const setUser = useAuthStoreSelectors.setUser();

  const { authentication: { getUserDetails } } = useApi();

  const { isConnected } = useNetworkProvider();
  const { isMaintenance } = useMaintenanceProvider();

  return useCallback(async () => {
    if (user?.id && isConnected && !isMaintenance) {
      setUser(await getUserDetails());
    }
  }, [getUserDetails, isConnected, isMaintenance, user, setUser]);

};
