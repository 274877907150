import { useApiStoreSelectors } from 'providers/ApiProvider/api.store.ts';
import { useIntlStoreSelectors } from 'providers/IntlProvider';
import { useWarningModal } from 'components';
import { useEffect } from 'react';
import messages from 'messages';

export const useApiUpdateAvailableCheck = () => {

  const updateAvailable = useApiStoreSelectors.updateAvailable();
  const translate = useIntlStoreSelectors.translate();

  const warningModal = useWarningModal();

  useEffect(() => {
    if (updateAvailable) {
      warningModal({
        title: translate(messages.general.newVersionAvailableModal.title),
        content: translate(messages.general.newVersionAvailableModal.content),
        okText: translate(messages.general.newVersionAvailableModal.reloadNow),
        onOk: () => window.location.reload(),
      });
    }
  }, [updateAvailable]);

};
