import categories from './categories/messages.ts';
import manufacturers from './manufacturers/messages.ts';
import products from './products/messages.ts';
import general from './general/messages.ts';

export default {
  categories,
  manufacturers,
  products,
  general,
};
