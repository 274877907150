import { useCallback } from 'react';
import { ComposedOrderRulesProperties, WriteableOrderProperties } from 'interfaces/api';
import { isNull, omitBy } from 'lodash';
import { useOrdersListContext } from 'modules/orders/providers/OrdersListProvider';
import { useHistory } from 'react-router';
import { useOfficeDoctorContext, useOrderRulesContext, useOrdersContext } from 'modules/orders/providers';

export type SaveOrExecuteOptions = {
  execute?: boolean;
  prePrint?: boolean;
  scheduled_at?: string;
  sample_in_lab?: boolean;
};

export const useSaveOrExecuteOrders = () => {

  const history = useHistory();

  const { logs, orders, globalSettings } = useOrdersContext();
  const { officeDoctor, reloadWizardSettings } = useOfficeDoctorContext();
  const { setPending: setPendingRules } = useOrderRulesContext();

  const { setListContext, onWizardDone } = useOrdersListContext();

  const onSaveDone = useCallback((response: ComposedOrderRulesProperties, resolve: any) => {
    const completed = () => {
      onWizardDone(response.order, resolve);
      reloadWizardSettings(officeDoctor.aid);
      history.push({ pathname: '/oa/' + response.order.id });
    };
    if (setListContext) {
      setListContext?.({ filters: { query: '' } }, false, completed);
    } else {
      completed();
    }
  }, [onWizardDone, history, setListContext, reloadWizardSettings, officeDoctor]);

  return useCallback((options: SaveOrExecuteOptions = {}, writeableOrders?: WriteableOrderProperties[]) => {
    return new Promise((resolve, reject) => {
      const { execute, prePrint, sample_in_lab } = options;
      const scheduled_at = options.scheduled_at || globalSettings.scheduled_at;
      const ordersData = (writeableOrders || orders).map(order => omitBy({ ...order, scheduled_at, sample_in_lab }, isNull));
      setPendingRules({
        reject,
        prePrint,
        execute,
        logs,
        isUpsert: true,
        resolve: result => onSaveDone(result, resolve),
        aid: officeDoctor?.aid,
        orders: ordersData,
      });
    });
  }, [setPendingRules, onSaveDone, officeDoctor, logs, orders]);

};
