import React, { useEffect, useRef, useState } from 'react';
import { useRenderContent, withContent, WithContentInnerProps, WithContentOuterProps } from 'hocs';
import { useRouteMatch } from 'react-router';
import { ApiRequest, ApiRequestUpdateBinding, ErrorBoundary, ListLayoutArgs, ListLayoutContext, ListLayoutUpdater, ResponseType } from 'containers';
import { Container, ControlGroup, ControlGroupProps, Header, HeaderProps, Icon, SwipeableContainer } from 'components';
import { Message } from 'interfaces';
import { Translate } from 'providers';
import { TopBarBackLink, TopBarControlMenu } from 'containers/App/TopBar/useTopBar';
import cx from 'classnames';
import { filter } from 'lodash';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FloatButton } from 'antd';
import { SwipeableProps } from 'react-swipeable';

export type ListLayoutHeaderControlGroup<C> = Omit<ControlGroupProps, 'children'> & {
  controls?: ListLayoutHeaderControl<C>[];
};
export type ListLayoutHeaderControl<C> = React.ComponentType<ListLayoutArgs<C>> | ListLayoutHeaderControlGroup<C>;

export type ListLayoutDetailHeaderProps<C> = Omit<HeaderProps, 'controls'> & {
  controls?: ListLayoutHeaderControl<C>[];
};

export type ListLayoutDetailSectionProps = {
  title?: Message;
  className?: any;
} & WithContentOuterProps;

const controlIsGroup = (control: ListLayoutHeaderControl<any>): control is ListLayoutHeaderControlGroup<any> => {
  return (control as ListLayoutHeaderControlGroup<any>).controls !== undefined;
};

export type ListLayoutHeader<C, E> = (args: ListLayoutDetailRenderProps<C, E>) => ListLayoutDetailHeaderProps<C>;

export type ListLayoutDetailProps<C extends ListLayoutContext, R> = {
  request: (id: number, args?: C) => R;
  onLoaded?: (args: ListLayoutDetailRenderProps<C, ResponseType<R>>) => void;
  context?: C;
  bindUpdateItem?: (update: ListLayoutUpdater) => void;
  bindReload?: (binding: () => void) => void;
  withDelay?: boolean;
  className?: string;
  baseUrl?: string;
  topBarTitle?: Message;
  header: ListLayoutHeader<C, ResponseType<R>>;
  onHandlePrev?: () => void;
  onHandleNext?: () => void;
} & WithContentOuterProps<ListLayoutDetailRenderProps<C, ResponseType<R>>>;

export type ListLayoutDetailRenderProps<Context, R = void> = {
  data: R;
} & ListLayoutArgs<Context>;

type Props<C, R> =
  & ListLayoutArgs<C>
  & ListLayoutDetailProps<C, R>
  ;

export const ListLayoutDetailSectionsHorizontal = (props: { sections: ListLayoutDetailSectionProps[] }) => {
  return filter(props.sections).length > 0
    ? (
      <Container horizontal className={'list-layout-detail-sections-horizontal'}>
        {filter(props.sections).map((s, idx) => (<ListLayoutDetailSection key={idx} {...s}/>))}
      </Container>
    )
    : null;
};

export const ListLayoutDetailSection = withContent((props: ListLayoutDetailSectionProps & WithContentInnerProps) => {
  return (
    <div className={cx('list-layout-detail-section', props.className)}>
      {props.title && <h2><Translate message={props.title}/></h2>}
      {props.renderContent()}
    </div>
  );
});

export const ListLayoutDetail = <C, DetailRequest>(props: Props<C, DetailRequest>) => {

  const [renderContent, rest] = useRenderContent(props);
  const { context, bindings, request, onLoaded, header, className, baseUrl, withDelay, topBarTitle } = rest;

  const { params } = useRouteMatch<{ id: string }>();

  const reloadRef = useRef<() => void>();
  const updateRef = useRef<ApiRequestUpdateBinding<ResponseType<any>>>();

  useEffect(() => {
    props.bindUpdateItem?.((id: number, item: any) => {
      updateRef.current?.(data => ({ ...data, ...item }));
    });
    props.bindReload?.(() => reloadRef.current?.());
  }, []);

  const [delta, setDelta] = useState(0);

  return (
    <TopBarBackLink backLink={{ title: topBarTitle, path: baseUrl }}>
      <ApiRequest
        bindUpdateData={(updateData) => {
          updateRef.current = updateData;
        }}
        delay={withDelay ? 220 : 0}
        key={params.id}
        request={() => request(parseInt(params.id), context)}
        onLoaded={(data) => {
          onLoaded?.({ data, context, bindings });
        }}
        bindReload={(reload) => {
          reloadRef.current = reload;
        }}
        children={({ data }) => {

          const renderArgs = { data, context, bindings };

          const mapControls = (controls: ListLayoutHeaderControl<C>[]) => (controls || []).filter(c => !!c).map((Control, index) => {
            if (controlIsGroup(Control)) {
              const { controls: childControls, ...rest } = Control;
              return <ControlGroup key={index} {...rest}>{mapControls(childControls)}</ControlGroup>;
            } else {
              return <Control key={index} {...renderArgs}/>;
            }
          });

          const { controls, ...computedHeaderProps } = header(renderArgs);
          const mappedControls = mapControls(controls);

          const headerProps: HeaderProps = {
            ...computedHeaderProps,
            backUrl: baseUrl,
            controls: mappedControls,
            hideControlsMobile: true,
          };

          const swipeableConfig: SwipeableProps = {
            delta: 10,
            onSwiped: eventData => setDelta(0),
            onSwipedLeft: eventData => eventData.deltaX < -100 && props.onHandleNext?.(),
            onSwipedRight: eventData => eventData.deltaX > 100 && props.onHandlePrev?.(),
            onSwiping: (eventData) => {
              setDelta(eventData.deltaX);
            },
          };

          return (
            <TopBarControlMenu controls={mappedControls} maxVisibleControls={headerProps.maxVisibleControls}>
              <SwipeableContainer grow shrink className={className} swipeableConfig={swipeableConfig}>
                {header && <Header {...headerProps}/>}
                <ErrorBoundary>
                  {renderContent(renderArgs)}
                </ErrorBoundary>
                {props.onHandlePrev && (
                  <FloatButton
                    shape={'circle'}
                    className={'is-hidden-tablet-up list-layout-navigate-prev'}
                    style={{ opacity: delta > 0 ? 1 / (100 / delta) : 0, transform: `translateX(${delta > 100 ? 0 : -25 + delta / 4}px)` }}
                    icon={<Icon icon={faChevronLeft}/>}
                    onClick={() => props.onHandlePrev()}
                  />
                )}
                {props.onHandleNext && (
                  <FloatButton
                    shape={'circle'}
                    style={{ opacity: delta < 0 ? 1 / (-100 / delta) : 0, transform: `translateX(${delta < -100 ? 0 : 25 + delta / 4}px)` }}
                    className={'is-hidden-tablet-up list-layout-navigate-next'}
                    icon={<Icon icon={faChevronRight}/>}
                    onClick={() => props.onHandleNext()}
                  />
                )}
              </SwipeableContainer>
            </TopBarControlMenu>
          );
        }}
      />
    </TopBarBackLink>
  );

};
