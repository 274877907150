import React from 'react';
import { Message } from 'interfaces';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import styles from './Search.module.less';
import { Control, ControlProps, Filter, Input, ScanModalControl } from 'components';
import { AutoComplete } from 'antd';
import { AdvancedAnalysisFilterType, Doctor, Patient, Platform } from 'interfaces/api';
import { injectApi, InjectedApiProps, Translate } from 'providers';
import cx from 'classnames';
import messages from 'messages';
import { AnyFunction, arrayify } from 'utils/helpers';
import dayjs from 'dayjs';
import { debounce, filter, isFunction, map } from 'lodash';
import { useEnvStore } from 'providers/EnvProvider';

export type SelectedAutocompleteFilter = {
  query: string;
  label: Message;
  filter: any;
};

export type SelectedAutocompleteGroupFilter = {
  group: string;
  item: any;
  filter?: any;
};

export type SelectedAutocomplete = SelectedAutocompleteFilter | SelectedAutocompleteGroupFilter;

export type SearchProps = {
  placeholder?: Message;
  onSearch: (value: any, reset: () => void) => void;
  onChange?: (value: string) => void;
  className?: any;
  autocomplete?: AutocompleteProps;
  onAutocomplete?: (selected: SelectedAutocomplete) => void;
  barcodeScanner?: boolean;
  searchControl?: ControlProps;
  disableFreetext?: boolean;
};

type Props = SearchProps & InjectedApiProps;

const autocompleteLabels = messages.general.search.autocomplete;

export type AutoCompleteGroupCallback = (items: any) => {
  label: Message;
  options: AutocompleteFilter[];
};

export type AutoCompleteGroup = {
  label: Message;
  filter?: AnyFunction;
  getTitle: (item: any) => React.ReactNode | string;
  getMeta?: (item: any) => React.ReactNode | string;
  wrapLabel?: (item: any, content: React.ReactNode) => React.ReactNode;
};

const patientsAutoCompleteGroup: AutoCompleteGroup = {
  label: autocompleteLabels.groups.patients,
  filter: (entity?: Patient) => ({ pid: entity?.pid }),
  getTitle: (entity: Patient) => entity.displayName,
  getMeta: (entity: Patient) => dayjs(entity.birthday).format('L'),
};

const doctorsAutoCompleteGroup: AutoCompleteGroup = {
  label: autocompleteLabels.groups.doctors,
  filter: (entity?: Doctor) => ({ aid: entity?.aid, lanr: entity?.lanr }),
  getTitle: (entity: Doctor) => entity.displayName,
  getMeta: (entity: Doctor) => entity?.lanr || entity.externalId,
};

const analysesAutoCompleteGroup: AutoCompleteGroupCallback = (items: string[]) => ({
  label: autocompleteLabels.groups.analyses,
  options: [{
    label: autocompleteLabels.requirement.label,
    queryLabelRender: (name: string) => <Translate message={autocompleteLabels.requirement.exists} values={{ name: name.toUpperCase() }}/>,
    filter: (name: string) => ({ requirement: { name: name.toUpperCase(), filterType: AdvancedAnalysisFilterType.Exists } }),
  }, {
    label: autocompleteLabels.requirement.label,
    queryLabelRender: (name: string) => <Translate message={autocompleteLabels.requirement.pathological} values={{ name: name.toUpperCase() }}/>,
    filter: (name: string) => ({ requirement: { name: name.toUpperCase(), filterType: AdvancedAnalysisFilterType.Patho } }),
  }, {
    label: autocompleteLabels.requirement.label,
    queryLabelRender: (name: string) => <Translate message={autocompleteLabels.requirement.extreme} values={{ name: name.toUpperCase() }}/>,
    filter: (name: string) => ({ requirement: { name: name.toUpperCase(), filterType: AdvancedAnalysisFilterType.Extreme } }),
  }],
});

export const AutoCompleteGroups = {
  patients: patientsAutoCompleteGroup,
  doctors: doctorsAutoCompleteGroup,
  analyses: analysesAutoCompleteGroup,
};

export type AutocompleteFilter = Filter & {
  regex?: string;
  queryLabelRender?: (...value: any[]) => React.ReactNode;
};

export type AutocompleteProps = {
  filters?: AutocompleteFilter[];
  dateFilters?: Filter[];
  groups?: Record<string, AutoCompleteGroup | AutoCompleteGroupCallback>;
  request?: (query: string) => Promise<any[]>;
  defaultQuery?: boolean;
  lanr?: boolean;
} & {
  [G in keyof typeof AutoCompleteGroups]?: boolean;
};

interface State {
  autocompleteItems?: Record<string, any[]>;
  query?: string;
  renderKey?: number;
  barcodeScannerAvailable?: boolean;
}

export const isAutocompleteFilter = (filter: SelectedAutocomplete): filter is SelectedAutocompleteFilter => {
  return (filter as SelectedAutocompleteFilter).label !== undefined;
};

export const isAutocompleteGroupFilter = (filter: SelectedAutocomplete): filter is SelectedAutocompleteGroupFilter => {
  return (filter as SelectedAutocompleteGroupFilter).group !== undefined;
};

export const AutocompleteItem = (props: { label: Message; value: React.ReactNode }) => (
  <span className={styles.autocompleteTitle}>
    <Translate message={props.label}/>
    <span className={styles.autocompleteValue}>{props.value}</span>
  </span>
);

class SearchClass extends React.Component<Props, State> {

  textRef = React.createRef<any>();

  constructor(props: Props) {
    super(props);
    this.state = {
      autocompleteItems: null,
      query: '',
      renderKey: 0,
    };
    this.loadAutocompleteItems = debounce(this.loadAutocompleteItems.bind(this), 250);
  }

  componentDidMount() {
    if (useEnvStore.getState().platform === Platform.WEB) {
      setTimeout(() => {
        this.textRef.current.focus();
      }, 500);
    }
  }

  handleSearch = (event?: any) => {
    event?.preventDefault();
    if (this.props.disableFreetext) {
      return;
    }
    this.props.onSearch(this.state.query, () => this.setState({ query: '' }));
  };

  onAutocompleteSelect = (value: string) => {

    const item: SelectedAutocomplete = JSON.parse(value);

    this.props.onAutocomplete(item);
    this.setState({ query: '' });

  };

  loadAutocompleteItems = async () => {

    const { autocomplete } = this.props;
    const { query } = this.state;

    if (autocomplete) {
      try {
        if (query.length >= 1) {
          const { request, patients, doctors, lanr, analyses: analysesShort } = autocomplete;
          const autocompleteItems = await (request
            ? request(query)
            : this.props.api.globals.getAutocomplete({
              query,
              patients,
              doctors,
              lanr,
              analysesShort,
            })) as any;
          this.setState({ autocompleteItems });
        }
      } catch (e) { /* empty */
      }
    }
  };

  onAutocompleteSearch = async (query: string) => {
    this.setState({ query }, this.loadAutocompleteItems);
  };

  getValidDateFromQuery(query: string) {
    const ddmmyyyy = query.match(/^(0[1-9]|[12][0-9]|3[01])(?:[-/.])?(?:(0[1-9]|1[012])(?:[-/.]?))?((?:19|20)\d\d)?$/);
    if (ddmmyyyy) {
      return dayjs((ddmmyyyy[3] || dayjs().year()) + '-' + (ddmmyyyy[2] || dayjs().format('MM')) + '-' + ddmmyyyy[1]);
    }

    const yyyymmdd = query.match(/^((?:19|20)\d\d)(?:[-/.])?(?:(0[1-9]|1[012])(?:[-/.]?))?(0[1-9]|[12][0-9]|3[01])?$/);
    if (yyyymmdd) {
      return dayjs((yyyymmdd[3] || dayjs().year()) + '-' + (yyyymmdd[2] || dayjs().format('MM')) + '-' + yyyymmdd[1]);
    }

    return null;
  }

  renderAutocompleteOptions() {

    const { autocomplete: { patients, doctors, defaultQuery, filters, dateFilters } } = this.props;
    const { autocompleteItems, query } = this.state;

    if (query.length === 0) {
      return [];
    }

    const groups = {
      patients: patients ? AutoCompleteGroups.patients : undefined,
      doctors: doctors ? AutoCompleteGroups.doctors : undefined,
      analyses: this.props.autocomplete.analyses ? AutoCompleteGroups.analyses : undefined,
      ...this.props.autocomplete.groups,
    };

    const renderFilter = (label: Message, query: string, queryNode: React.ReactNode, filter: any) => ({
      label: <AutocompleteItem label={label} value={queryNode}/>,
      value: JSON.stringify({ query, filter, label } as SelectedAutocompleteFilter),
    });

    const renderQueryFilter = (filter: AutocompleteFilter) => {
      const matchedQuery = arrayify(filter.regex ? query.match(filter.regex) : query);
      return renderFilter(filter.label, query, filter.queryLabelRender?.(...matchedQuery) || `${query}...`, filter.filter(...matchedQuery));
    };

    let options: any = [];

    if (defaultQuery) {
      options.push(renderQueryFilter({
        label: messages.general.search.autocomplete.default,
        filter: query => ({ query }),
      }));
    }

    options = options.concat(arrayify(filter(filters, f => !f.regex || query.match(f.regex))).map(renderQueryFilter));

    const dateQuery = this.getValidDateFromQuery(query);

    if (dateQuery && dateFilters?.length > 0) {
      options = options.concat({
        label: <span className={styles.autocompleteGroupHeader}><Translate message={messages.general.search.dateSearch}/></span>,
        options: arrayify(dateFilters).map(filter => renderFilter(filter.label, dateQuery.format('L'), dateQuery.format('L'), filter.filter(dateQuery.format('YYYY-MM-DD')))),
      });
    }

    options = options
      .concat(filter(map(autocompleteItems || {}, (children, group) => {

        // @ts-expect-error todo
        const autocompleteGroup = groups[group];

        if (children.length === 0) {
          return null;
        }

        const wrapHeader = (message: Message) => (
          <span className={styles.autocompleteGroupHeader}>
            <Translate message={message}/>
          </span>
        );

        if (isFunction(autocompleteGroup)) {
          const result = autocompleteGroup(children);
          return { label: wrapHeader(result.label), options: result.options.map(renderQueryFilter) };
        }

        const wrapLabel = (item: any, content: React.ReactElement) => {
          return autocompleteGroup.wrapLabel?.(item, content) || content;
        };

        return {
          label: wrapHeader(autocompleteGroup.label),
          options: children.map(item => ({
            label: wrapLabel(item, (
              <span className={styles.autocompleteLabel}>
                <span className={styles.autocompleteTitle}>
                  {autocompleteGroup.getTitle(item)}
                </span>
                {autocompleteGroup.getMeta && (
                  <span className={styles.autocompleteSubtitle}>
                    {autocompleteGroup.getMeta(item)}
                  </span>
                )}
              </span>
            )),
            value: JSON.stringify({
              group,
              item,
              filter: autocompleteGroup.filter ? autocompleteGroup.filter(item) : undefined,
            } as SelectedAutocompleteGroupFilter),
          })),
        };

      })));

    return options;
  }

  handleScanned = (barcode: string) => {
    this.setState({ query: barcode }, () => {
      this.props.onSearch(this.state.query, () => this.setState({ query: '' }));
    });
  };

  handleChange = (query: string) => {
    this.setState({ query });
    this.props.onChange?.(query);
  };

  render() {

    const { className, autocomplete, barcodeScanner } = this.props;
    const placeholder = this.props.placeholder || messages.general.search.placeholder;

    const isWeb = useEnvStore.getState().isWeb;

    return (
      <div className={cx(styles.container, className)}>
        <Control
          onClick={this.handleSearch}
          icon={faSearch}
          className={styles.control}
          {...this.props.searchControl}
        />
        {autocomplete
          ? (
            <AutoComplete
              autoFocus={isWeb}
              value={this.state.query}
              ref={this.textRef}
              options={this.renderAutocompleteOptions()}
              popupClassName={styles.autocomplete}
              onSelect={this.onAutocompleteSelect}
              onSearch={this.onAutocompleteSearch}
              popupMatchSelectWidth={false}
              key={this.state.renderKey}
              defaultActiveFirstOption={true}
            >
              <Input
                className={'input'}
                type="search"
                placeholder={placeholder}
                onPressEnter={this.handleSearch}
                onChange={event => this.handleChange(event.target.value)}
              />
            </AutoComplete>
          )
          : (
            <Input
              ref={this.textRef}
              autoFocus={isWeb}
              type="search"
              className={'input'}
              value={this.state.query}
              onChange={event => this.handleChange(event.target.value)}
              onPressEnter={this.handleSearch}
              placeholder={placeholder}
            />
          )}
        {this.state.query.length === 0 && barcodeScanner && (
          <ScanModalControl
            title={messages.general.search.searchModal.title}
            close={messages.general.close}
            className={cx(styles.barcodeScanner, 'search-barcode-scanner')}
            onScanned={this.handleScanned}
          />
        )}
        {this.state.query.length > 0 && (
          <Control
            icon={faTimes}
            className={styles.reset}
            onClick={() => this.setState({
              query: '',
              renderKey: this.state.renderKey + 1,
            }, this.handleSearch)}
          />
        )}
      </div>
    );

  }

}

export const Search = injectApi(SearchClass);
