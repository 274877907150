import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Control, DraggableContainer, Flag, Modal, ModalControl, ModalProps, Popover, Select, Tooltip } from 'components';
import { useApi, useConfig, useSetConfig, useTranslate } from 'providers';
import dayjs from 'dayjs';
import './Cumulative.less';
import { getReportValueStatus, useCumulativeGrouping, useFavoriteAnalysesHistory, useShowPathoColumn } from 'modules/reports/utils';
import cx from 'classnames';
import messages from 'messages';
import { faTable } from '@fortawesome/pro-light-svg-icons';
import { PoctFilter, ReportValueStatus } from 'modules/reports/interfaces';
import { filter, flatten, groupBy, keys, map, uniqBy, values } from 'lodash';
import { AnyFunction } from 'utils/helpers';
import { ApiRequest, Guard } from 'containers';
import { faArrowsFromLine, faArrowsToLine } from '@fortawesome/pro-regular-svg-icons';
import { Feature, LaboratoryGroupFilter, Report, ReportHistoryValue } from 'interfaces/api';
import { ReportControlContext } from 'modules/reports/index';
import { ReportsRoot } from 'modules/reports/containers/ReportsRoot/ReportsRoot';
import { useReportsConfig } from 'modules/reports/providers/ReportsConfigProvider';
import { ReportDetail } from 'modules/reports/components/Detail/Detail.tsx';
import { HexColor } from 'interfaces';

const labels = messages.reports.controls.cumulative;
const filterLabels = messages.reports.filters;
const poctFilterLabels = messages.reports.details.poctFilter;

export type CumulativeViewProps = {
  bid: number;
  patientName: string;
  groupByCase?: boolean;
  showPoctOnly?: PoctFilter;
  lgFilter?: string;
};

type CumulativeReport = {
  bid: number;
  date: dayjs.Dayjs;
  tnr: string;
  caseNumber: string;
  laboratoryGroup: string;
};

export const CumulativeView: React.FC<CumulativeViewProps> = (props) => {

  const { bid, groupByCase, showPoctOnly, lgFilter } = props;
  const translate = useTranslate();

  const { reportDisplay: { alwaysShowDateAndTimeInCumulativeDisplay } } = useReportsConfig();
  const { preferences: { reportsCumulativeMergeDays } } = useConfig();

  const reportHistoryWithFavorites = useFavoriteAnalysesHistory();
  const cumulativeGrouping = useCumulativeGrouping();

  const showPathoColumn = useShowPathoColumn();

  const [showReportModal, setShowReportModal] = useState<CumulativeReport>();
  const { reports: { readReport } } = useApi();

  return (
    <Container horizontal grow className={'cumulative-wrapper'}>
      <ApiRequest
        request={() => reportHistoryWithFavorites(props.bid)}
        children={({ data }) => {

          const filteredData = data.filter(v => !(showPoctOnly === PoctFilter.PoctOnly && !v?.poct) && !(showPoctOnly === PoctFilter.NonPoctOnly && !!v?.poct));

          const allValues = flatten(filteredData.map(result => result.values.filter(v => !lgFilter || v.blg.toUpperCase() === lgFilter.toUpperCase())));

          const reportDates: CumulativeReport[] = uniqBy(allValues, v => reportsCumulativeMergeDays ? dayjs(v.date).startOf('day').valueOf() : v.date + v.btnr)
            .map(v => ({
              bid: v.bid,
              date: reportsCumulativeMergeDays ? dayjs(v.date).startOf('day') : dayjs(v.date),
              tnr: v.btnr,
              caseNumber: v.caseNumber,
              laboratoryGroup: v.laboratoryGroup,
            }))
            .sort((a, b) => b.date.valueOf() - a.date.valueOf())
          ;

          const groupedByCases = groupByCase ? values(groupBy(reportDates, r => r.caseNumber)) : [reportDates];

          const groupedValues = groupBy(filteredData, cumulativeGrouping.groupByValue);

          return (
            <DraggableContainer
              scrollX
              scrollY
              className={cx('cumulative-container', { 'cumulative-with-cases': groupByCase })}
            >
              <div>

                {groupByCase && (
                  <ul className={'cumulative-header-cases'}>
                    <li className={'cumulative-divider'}/>
                    {groupedByCases.map(c => (
                      <li key={c[0].caseNumber || 'no-case'} style={{ width: `calc(var(--ob-cumulative-view-cell-width) * ${c.length})` }}>
                        {c[0].caseNumber || translate(messages.cases.noCase)}
                      </li>
                    ))}
                  </ul>
                )}

                <ul className={'cumulative-column-header'}>
                  <li className={'cumulative-divider'}/>
                  {groupedByCases.map((c) => {
                    let prev: string;
                    return c.map((report) => {

                      const formattedDate = report.date.format(reportsCumulativeMergeDays
                        ? 'L'
                        : (prev === report.date.format('L') && !alwaysShowDateAndTimeInCumulativeDisplay) ? 'LT' : 'L LT');
                      prev = report.date.format('L');

                      return (
                        <li key={report.bid} className={cx({ 'is-active': report.bid === bid })} onClick={() => setShowReportModal(report)}>
                          <span className={'cumulative-column-header-timestamp'}>{formattedDate}</span>
                          {!reportsCumulativeMergeDays && <span className={'cumulative-column-header-tnr'}>{report.tnr}</span>}
                        </li>
                      );
                    });
                  })}
                </ul>

                <div className={'cumulative-key-and-values'}>

                  <ul className={'cumulative-row-header'}>
                    {map(groupedValues, (values: ReportHistoryValue[], group) => (
                      <React.Fragment key={group || 1}>
                        {keys(groupedValues).length > 1 && (
                          <Tooltip title={group} placement={'right'} key={group}>
                            <li className={cx('cumulative-key-group', { 'list-header-favorite': group === translate(messages.reports.sidebar.settings.tabs.analyses) })}>
                              <h3>{group}</h3>
                            </li>
                          </Tooltip>
                        )}
                        {map(values, result => (
                          <Tooltip title={result.name + ' - ' + result.description} placement={'right'} key={result.name + ':' + result.group}>
                            <li>
                              <span className={'cumulative-key-group-name'}>{result.name}</span>
                              <span className={'cumulative-key-group-description'}>{result.description}</span>
                            </li>
                          </Tooltip>
                        ))}
                      </React.Fragment>
                    ))}
                  </ul>

                  <div className={'cumulative-values'}>

                    {map(groupedValues, (values: ReportHistoryValue[], group) => (
                      <React.Fragment key={group || 1}>
                        {keys(groupedValues).length > 1 && (
                          <div className={'cumulative-key-group'} style={{ width: `calc(var(--ob-cumulative-view-cell-width) * ${reportDates.length})` }}/>
                        )}

                        {map(values, value => (
                          <div key={value.name} className={'cumulative-value-row'}>

                            {groupedByCases.map(c => c.map((report) => {

                              const reportValue = value.values.sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf()).filter((v) => {
                                const valueDate = reportsCumulativeMergeDays ? dayjs(v.date).startOf('day') : dayjs(v.date);
                                const isSameTnr = reportsCumulativeMergeDays ? true : v.btnr === report.tnr;
                                return v.caseNumber === report.caseNumber && isSameTnr && valueDate.isSame(report.date) && v.result?.length > 0;
                              })[0];

                              if (!reportValue) {
                                return (
                                  <div key={report.bid + value.name} className={cx('cumulative-value-cell', { 'is-active': bid === report.bid })}/>
                                );
                              }

                              const status = getReportValueStatus(reportValue);

                              const cellStyles = {
                                'is-patho': status === ReportValueStatus.Pathological,
                                'is-active': bid === report.bid,
                              };

                              const renderedValueCell = (
                                <>
                                  <span className={'cumulative-value'}>
                                    {reportValue.pathological && showPathoColumn && (
                                      <span className={'cumulative-patho-value'}>
                                        {reportValue.pathological}
                                      </span>
                                    )}
                                    {reportValue.textResult || reportValue.result}&nbsp;{reportValue.unit}
                                  </span>
                                  <span className={'cumulative-value-norm'}>
                                    {reportsCumulativeMergeDays && (<span className={'cumulative-value-lg'}>{reportValue.laboratoryGroup}</span>)}
                                    {reportValue.reference.text} {reportValue.unit}
                                  </span>
                                </>
                              );

                              return (
                                <Popover
                                  destroyTooltipOnHide
                                  mouseEnterDelay={0.01}
                                  overlayStyle={{ pointerEvents: 'none' }}
                                  align={{ offset: [0, 54] }}
                                  arrow={false}
                                  content={
                                    <div
                                      className={cx('cumulative-value-popover cumulative-value-cell', cellStyles)}
                                      onClick={() => setShowReportModal(report)}
                                    >
                                      {renderedValueCell}
                                    </div>
                                  }
                                >
                                  <div
                                    key={report.date.valueOf() + report.tnr}
                                    className={cx('cumulative-value-cell', cellStyles)}
                                    onClick={() => setShowReportModal(report)}
                                  >
                                    {renderedValueCell}
                                  </div>
                                </Popover>
                              );
                            }))}
                          </div>
                        ))}
                      </React.Fragment>
                    ))}

                  </div>

                </div>

              </div>
            </DraggableContainer>
          );
        }}
      />

      <Modal
        fullHeight
        footer={null}
        open={!!showReportModal}
        onCancel={() => setShowReportModal(undefined)}
        title={props.patientName + ' - ' + showReportModal?.tnr}
        children={() => (
          <ApiRequest
            request={() => readReport({ bid: showReportModal.bid })}
            children={({ data }) => (
              <ReportDetail data={data}/>
            )}
          />
        )}
      />

    </Container>
  );

};

export const useCumulativeModalProps = (
  data: Report,
  groupByCase?: boolean,
  initialShowPoctOnly?: PoctFilter,
  lgFilters?: LaboratoryGroupFilter[],
  initialLgFilter?: string,
): ModalProps => {

  const translate = useTranslate();

  const config = useConfig();
  const setConfig = useSetConfig();

  const { preferences } = config;
  const [showPoctOnly, setShowPoctOnly] = useState<PoctFilter | undefined>(initialShowPoctOnly);
  const [lgFilter, setLgFilter] = useState<string>(initialLgFilter);

  useEffect(() => {
    setShowPoctOnly(initialShowPoctOnly);
  }, [initialShowPoctOnly]);

  const handleToggleMergeDays = useCallback(() => {
    setConfig({ ...config, preferences: { ...preferences, reportsCumulativeMergeDays: !preferences?.reportsCumulativeMergeDays } });
  }, [setConfig, preferences]);

  const poctValues = filter(data.values?.map(v => v?.poct));

  return useMemo(() => ({
    title: data.patientName + ' - ' + translate(labels.label),
    children: () => (
      <CumulativeView
        bid={data.bid}
        patientName={data.patientName}
        groupByCase={groupByCase}
        showPoctOnly={showPoctOnly}
        lgFilter={lgFilter}
      />
    ),
    fullScreen: true,
    footer: null,
    controls: filter([
      lgFilters?.length > 0 && (
        <Guard feature={Feature.LaboratoryGroupReportFilter}>
          <Select
            key={'lg-filter'}
            className={'margin-left-1'}
            value={lgFilter}
            placeholder={translate(filterLabels.laboratoryGroup)}
            onChange={e => setLgFilter(e)}
            onClear={() => setLgFilter(undefined)}
            popupMatchSelectWidth={false}
            allowClear
            options={lgFilters.map(item => ({
              value: item.group,
              children: (
                <>
                  {item.color && <Flag colors={new HexColor(item.color)}/>}
                  {item.name || item.group}
                </>
              ),
            }))}
          />
        </Guard>
      ),
      poctValues.length > 0 && poctValues.length < data.values.length && (
        <Guard feature={Feature.Poct}>
          <Select
            key={'poct-filter'}
            value={showPoctOnly}
            className={'margin-left-1'}
            placeholder={translate(poctFilterLabels.placeholder)}
            onChange={e => setShowPoctOnly(e)}
            onClear={() => setShowPoctOnly(undefined)}
            popupMatchSelectWidth={false}
            allowClear
            options={[
              { value: PoctFilter.PoctOnly, label: poctFilterLabels.poctOnly },
              { value: PoctFilter.NonPoctOnly, label: poctFilterLabels.nonPoctOnly },
            ]}
          />
        </Guard>
      ),
      (
        <Control
          key={'merge-days'}
          icon={{ icon: preferences.reportsCumulativeMergeDays ? faArrowsFromLine : faArrowsToLine, rotation: 90 }}
          label={preferences.reportsCumulativeMergeDays ? labels.expandSameDays : labels.mergeSameDays}
          onClick={() => handleToggleMergeDays()}
        />
      ),
    ]),
  }), [data, handleToggleMergeDays, preferences, poctValues, lgFilters]);

};

export const CumulativeControl = (props: ReportControlContext & Omit<CumulativeViewProps, 'bid' | 'patientName'> & { onClick?: AnyFunction }) => {

  const { data, onClick, groupByCase } = props;
  const modal = useCumulativeModalProps(data, groupByCase, props.context?.showPoctOnly, props.context?.laboratoryGroupFilters, props.context?.filters.laboratoryGroup);

  return (
    <ReportsRoot>
      <ModalControl
        icon={faTable}
        label={labels.label}
        onClick={onClick}
        tooltip={{ placement: 'bottomRight' }}
        modal={modal}
      />
    </ReportsRoot>
  );

};
