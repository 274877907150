import { useEffect, useState } from 'react';
import constate from 'constate';
import * as SocketIO from 'socket.io-client';
import { useEnvStore } from 'providers/EnvProvider';

const socketContext = () => {

  const [connection, setConnection] = useState<SocketIO.Socket>();

  const [error, setError] = useState();

  const { BACKEND_URL } = useEnvStore.getState();

  useEffect(() => {

    connection?.disconnect();

    const { host, protocol, pathname } = new URL(BACKEND_URL + '/notifications');

    const socket = SocketIO.io(protocol + '//' + host, {
      path: pathname,
      transports: ['websocket', 'polling'],
      withCredentials: true,
      extraHeaders: {
        'x-version': APP_VERSION,
      },
    });

    socket.on('connect', () => {
      setConnection(socket);
    });

    socket.on('connect_error', (error: any) => {
      setConnection(undefined);
      setError(error);
    });

    socket.on('error', (error: any) => {
      setConnection(undefined);
      setError(error);
    });

    socket.on('disconnect', () => {
      setConnection(undefined);
    });

  }, []);

  return { connection, error };

};

const [SocketProvider, useSocketConnection] = constate(socketContext);

export {
  SocketProvider,
  useSocketConnection,
};
