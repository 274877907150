import React from 'react';
import messages from 'messages';
import { FormItemRenderProps } from 'components/Form/Item';
import { BookmarkedMode, CompletionListOrderBy, OrderDetailDefaultGroupBy, OrderDetailDefaultSortBy, OrderListMode, OrdersPreferencesProperties } from 'interfaces/api';
import { splitEnumOptions } from 'utils/helpers.ts';

const preferencesLabels = messages.orders.preferences;

export const OrdersSettingsForm: React.ComponentType<FormItemRenderProps<OrdersPreferencesProperties>> = (props) => {

  const { Select, value, onChange } = props;

  const orderModes = [{ value: -1, label: messages.orders.mode.home }, ...splitEnumOptions(OrderListMode, messages.orders.modes)];

  return (
    <>
      <Select
        label={preferencesLabels.ordersDefaultOrderMode}
        property={'ordersDefaultOrderMode'}
        value={value.ordersDefaultOrderModeEnabled ? value.ordersDefaultOrderMode : -1}
        onChange={value => onChange({ ordersDefaultOrderMode: value, ordersDefaultOrderModeEnabled: value !== -1 })}
        options={orderModes}
      />
      <Select
        label={preferencesLabels.ordersDefaultStatusInBookmarked}
        property={'ordersDefaultStatusInBookmarked'}
        options={splitEnumOptions(BookmarkedMode, messages.orders.filters.bookmarkedModes)}
      />
      <Select
        label={preferencesLabels.ordersDefaultCompletionOrderBy}
        property={'ordersDefaultCompletionOrderBy'}
        options={splitEnumOptions(CompletionListOrderBy, messages.orders.filters.completionOrder)}
      />
      <Select
        label={preferencesLabels.ordersDetailDefaultGroupBy}
        property={'ordersDetailDefaultGroupBy'}
        options={splitEnumOptions(OrderDetailDefaultGroupBy, messages.orders.groupBy.values)}
      />
      <Select
        label={preferencesLabels.ordersDetailDefaultSortBy}
        property={'ordersDetailDefaultSortBy'}
        options={splitEnumOptions(OrderDetailDefaultSortBy, messages.orders.sortBy.values)}
      />
    </>
  );

};
