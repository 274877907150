import React from 'react';
import { Axis } from 'react-sortable-hoc';
import { Message } from 'interfaces';
import { withSuspense } from 'hocs';
import { ControlProps } from 'components/Control';

export type DynamicFieldsProps<T> = {
  children?: React.ReactNode;
  getControls?: (index: number) => React.ReactElement<ControlProps>[];
  itemReadOnly?: (index: number) => boolean;
  value?: T[];
  onChange?: (values: T[]) => void;
  disabled?: boolean;
  getRequired?: (property: any) => boolean;
  error?: any;
  className?: string;
  checkable?: boolean;
  onCheckChange?: (values: T[], index: number, checked: boolean) => T[];
  checked?: (index: number) => boolean;
  sortable?: boolean;
  itemSortable?: (index: number) => boolean;
  onSortEnd?: (values: T[]) => T[];
  axis?: Axis;
  addLabel?: Message;
  hideAdd?: boolean;
  addControl?: (add: (value?: any) => void) => React.ReactNode;
  childClassName?: string;
  min?: number;
  max?: number;
  horizontal?: boolean;
  collapse?: {
    accordion?: boolean;
    title?: (value: any, index: number) => Message;
  };
};

export const DynamicFields = withSuspense(React.lazy(() => import('./DynamicFields')));
