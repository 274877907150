import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiRequest, Guard, ListLayoutDetailRenderProps, ListLayoutDetailSection } from 'containers';
import { ReportListContext } from 'modules/reports';
import { Feature, Report } from 'interfaces/api';
import { Container, Icon, List, ListItem, Modal, Select, ToolBar } from 'components';
import messages from 'messages';
import { useGraph } from 'modules/reports/components/Graph/Graph';
import { getReportValuesListHeader, hasNoPrevValue, hasPrevValue, useShowPathoColumn } from 'modules/reports/utils';
import { SortGrouping } from 'interfaces';
import './Detail.less';
import cx from 'classnames';
import { useApi, useTranslate } from 'providers';
import { filter, findIndex, sortBy } from 'lodash';
import { useReportsConfig } from 'modules/reports/providers/ReportsConfigProvider';
import { useCumulativeModalProps } from 'modules/reports/components/Controls';
import { useConvertReportValuesToListItems } from 'modules/reports/providers/ReportListLayoutProvider';
import { Carousel, FloatButton } from 'antd';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { PoctFilter } from 'modules/reports/interfaces.ts';

type Props = ListLayoutDetailRenderProps<ReportListContext, Report>;

enum PathoFilter {
  PathoOnly = 1,
  NonPathoOnly = 2,
}

enum PrevValueFilter {
  WithPrevValue = 1,
  WithoutPrevValue = 2,
}

export const ReportDetail: React.FC<Props> = (props) => {

  const translate = useTranslate();
  const labels = messages.reports.details;

  const reports = [props.data, ...(props.data.subreports || [])];
  const report = useMemo(() => reports[props.context?.selectedReport || 0], [props.context?.selectedReport]);

  const { showBdw, showOriginal, values, orderComment } = report;

  const Graph = useGraph();
  const { reports: { getReportOriginalPdfImages, readReport } } = useApi();

  const convertReportValuesToListItems = useConvertReportValuesToListItems();

  const [selected, onSelect] = useState<ListItem>(undefined);
  const handleSelect = useCallback((item: ListItem) => onSelect(selected && item.id === selected.id ? undefined : item), [report.bid]);

  const { preferences } = useReportsConfig();
  const reportsInitialOpenCumulative = preferences?.reportsInitialOpenCumulative;
  const [showCumulativeView, setShowCumulativeView] = useState(reportsInitialOpenCumulative && !hasNoPrevValue(values));

  const [showPathoOnly, setShowPathoOnly] = useState<PathoFilter | undefined>();
  const [showPrevValuesOnly, setShowPrevValuesOnly] = useState<PrevValueFilter | undefined>();
  const [showPoctOnly, setShowPoctOnly] = useState<PoctFilter | undefined>();
  const [showReportModal, setShowReportModal] = useState<Report>();

  useEffect(() => {
    props.bindings?.setContext({ showPoctOnly });
  }, [showPoctOnly]);

  const filteredValues = useMemo(() => filter(values, (v) => {
    return (
      !(showPathoOnly === PathoFilter.PathoOnly && !v?.pathological)
      && !(showPathoOnly === PathoFilter.NonPathoOnly && !!v?.pathological)
      && !(showPrevValuesOnly === PrevValueFilter.WithPrevValue && !hasPrevValue(v))
      && !(showPrevValuesOnly === PrevValueFilter.WithoutPrevValue && hasPrevValue(v))
      && !(showPoctOnly === PoctFilter.PoctOnly && !v?.poct)
      && !(showPoctOnly === PoctFilter.NonPoctOnly && !!v?.poct)
    );
  }), [showPathoOnly, showPrevValuesOnly, showPoctOnly, report]);

  const generatedItems = useMemo(
    () => convertReportValuesToListItems(filteredValues, report, Graph, setShowReportModal),
    [report.bid, filteredValues, convertReportValuesToListItems],
  );
  const generatedGroupHeader = useMemo(() => getReportValuesListHeader(filteredValues), [report.bid, filteredValues]);

  const filteredAnalyses = useMemo(
    () => sortBy(
      filter(values, v => preferences?.reportsFavoriteAnalyses.map(a => a.shortName?.toLowerCase()).includes(v.name?.toLowerCase())),
      v => findIndex(preferences?.reportsFavoriteAnalyses, a => a.shortName?.toLowerCase() === v.name?.toLowerCase()),
    ).map(v => ({ ...v, group: translate(messages.reports.sidebar.settings.tabs.analyses) })),
    [preferences?.reportsFavoriteAnalyses, report],
  );
  const generatedFavorites = useMemo(() => convertReportValuesToListItems(filteredAnalyses, report, Graph, setShowReportModal, 'fav-'), [report.bid, filteredAnalyses, convertReportValuesToListItems]);
  const generatedFavoritesHeader = useMemo(() => getReportValuesListHeader(filteredAnalyses), [report.bid, filteredAnalyses]);

  const showPathoColumn = useShowPathoColumn();

  const className = useMemo(() => cx('report-detail-list', {
    ['report-detail-show-patho-column']: showPathoColumn,
  }), [showPathoColumn]);

  const pathoValues = filter(values.map(v => v?.pathological));
  const poctValues = filter(values.map(v => v?.poct));

  const pathoFilter = useMemo(() => pathoValues.length > 0 && pathoValues.length < values.length && (
    <Container>
      <Select
        value={showPathoOnly}
        placeholder={translate(labels.pathoFilter.placeholder)}
        onChange={e => setShowPathoOnly(e)}
        onClear={() => setShowPathoOnly(undefined)}
        popupMatchSelectWidth={false}
        allowClear
        options={[
          { value: PathoFilter.PathoOnly, label: labels.pathoFilter.pathoOnly },
          { value: PathoFilter.NonPathoOnly, label: labels.pathoFilter.nonPathoOnly },
        ]}
      />
    </Container>
  ), [report]);

  const prevFilter = useMemo(() => !hasNoPrevValue(values) && (
    <Container className={cx({ 'margin-left-1': !!pathoFilter })}>
      <Select
        value={showPrevValuesOnly}
        placeholder={translate(labels.prevValueFilter.placeholder)}
        onChange={e => setShowPrevValuesOnly(e)}
        onClear={() => setShowPrevValuesOnly(undefined)}
        popupMatchSelectWidth={false}
        allowClear
        options={[
          { value: PrevValueFilter.WithPrevValue, label: labels.prevValueFilter.prevOnly },
          { value: PrevValueFilter.WithoutPrevValue, label: labels.prevValueFilter.nonPrevOnly },
        ]}
      />
    </Container>
  ), [report]);

  const poctFilter = useMemo(() => poctValues.length > 0 && poctValues.length < values.length && (
    <Guard feature={Feature.Poct}>
      <Container className={cx({ 'margin-left-1': !!(pathoFilter || prevFilter) })}>
        <Select
          value={showPoctOnly}
          placeholder={translate(labels.poctFilter.placeholder)}
          onChange={e => setShowPoctOnly(e)}
          onClear={() => setShowPoctOnly(undefined)}
          popupMatchSelectWidth={false}
          allowClear
          options={[
            { value: PoctFilter.PoctOnly, label: labels.poctFilter.poctOnly },
            { value: PoctFilter.NonPoctOnly, label: labels.poctFilter.nonPoctOnly },
          ]}
        />
      </Container>
    </Guard>
  ), [report]);

  return (
    <>
      <Container grow shrink scrollY translateZero style={{ marginBottom: '-1px' }}>

        {report.diagnosis?.length > 0 && report.diagnosis !== '.' && (
          <ListLayoutDetailSection
            title={messages.general.diagnosis}
            children={<p>{report.diagnosis}</p>}
          />
        )}

        {orderComment?.length > 0 && (
          <ListLayoutDetailSection
            title={labels.orderComments}
            children={orderComment.split('~').filter(c => !!c).map((c, i) => (
              <p key={i}>{c}</p>
            ))}
          />
        )}

        {showBdw && (
          <>
            {generatedFavorites.length > 0 && (
              <ListLayoutDetailSection className={'report-detail-my-analyses-section'}>
                <List
                  className={cx(className, 'report-detail-my-analyses')}
                  selected={selected ? [selected] : undefined}
                  onSelect={handleSelect}
                  items={generatedFavorites.map(i => ({ ...i, groupByValue: translate(messages.reports.sidebar.settings.tabs.analyses) }))}
                  groupHeader={generatedFavoritesHeader}
                  groupBy={SortGrouping.STRING}
                />
              </ListLayoutDetailSection>
            )}
            {(prevFilter || pathoFilter || poctFilter) && (
              <ToolBar className={'border-top is-hidden-mobile'}>
                {pathoFilter}
                {prevFilter}
                {poctFilter}
              </ToolBar>
            )}
            <List
              className={className}
              groupBy={SortGrouping.STRING}
              selected={selected ? [selected] : undefined}
              onSelect={handleSelect}
              items={generatedItems}
              groupHeader={generatedGroupHeader}
            />
          </>
        )}

        {showOriginal && (
          <ApiRequest
            staticLoader
            request={() => getReportOriginalPdfImages({ bid: report.bid })}
            children={({ data }) => (
              <Carousel dotPosition={'top'}>
                {data.map((file, idx) => (
                  <div key={idx} className={'report-original-pdf'}>
                    <img src={'data:image/png;base64,' + file}/>
                  </div>
                ))}
              </Carousel>
            )}
          />
        )}

      </Container>

      {pathoFilter && (
        <FloatButton
          className={'is-hidden-tablet-up'}
          icon={<Icon icon={faExclamationTriangle}/>}
          type={showPathoOnly ? 'primary' : undefined}
          onClick={() => setShowPathoOnly(showPathoOnly ? undefined : PathoFilter.PathoOnly)}
          badge={{ count: pathoValues.length }}
        />
      )}

      {reportsInitialOpenCumulative && (
        <Modal
          {...useCumulativeModalProps(props.data)}
          open={showCumulativeView}
          onCancel={() => setShowCumulativeView(false)}
        />
      )}

      <Modal
        fullHeight
        footer={null}
        open={!!showReportModal}
        onCancel={() => setShowReportModal(undefined)}
        title={props.data.patientName + ' - ' + showReportModal?.tnr}
        children={() => (
          <ApiRequest
            request={() => readReport({ bid: showReportModal.bid })}
            children={({ data }) => (
              <ReportDetail data={data}/>
            )}
          />
        )}
      />
    </>
  );

};
