import { isI18nMessage, Message } from 'interfaces';
import MessageFormat from 'messageformat';
import { IntlState } from 'providers/IntlProvider/IntlProvider.tsx';

export type TranslateFunc = (message: Message, values?: Record<string, any>) => string | undefined;

export const useTranslateFunction = (get: () => IntlState): TranslateFunc => {

  return (message, values) => {

    const mf = new MessageFormat(get().locale);

    if (!message) {
      return undefined;
    }

    if (typeof message === 'string') {
      return message;
    }

    if (isI18nMessage(message)) {

      const key = get().translations[message.id] || message.defaultMessage;

      if (!key) {
        return undefined;
      }

      try {
        return mf.compile(key)(values || {}).toString();
      } catch (e) {
        console.error('something is wrong with message', message);
        return key;
      }
    }

    return message as any;

  };
};
