import { MutableRefObject, useCallback } from 'react';
import constate from 'constate';
import { ListLayoutArgs } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { ComposedOrderProperties } from 'interfaces/api';
import { useWizardToggleContext } from 'modules/orders/providers/WizardToggleProvider';

const useOrdersList = (initialValue: { listRef?: MutableRefObject<ListLayoutArgs<OrdersListContext>> }) => {

  const listRef = initialValue.listRef?.current;

  const setListContext = useCallback(listRef?.bindings?.setContext, [listRef]);
  const reloadDetail = useCallback(listRef?.bindings?.reloadDetail, [listRef]);
  const updateItem = useCallback(listRef?.bindings?.updateItem, [listRef]);

  const wizardToggle = useWizardToggleContext();

  const onWizardDone = useCallback((response?: ComposedOrderProperties, resolve?: (value: unknown) => void) => {
    wizardToggle[1](false);
    listRef?.bindings?.reloadList?.();
    if (response) {
      updateItem?.(response.id, response);
    }
    resolve?.(response);
  }, [wizardToggle, updateItem]);

  return {
    setListContext,
    reloadDetail,
    updateItem,
    onWizardDone,
    reloadList: listRef?.bindings?.reloadList,
  };

};

const [OrdersListProvider, useOrdersListContext] = constate(useOrdersList);
export { OrdersListProvider, useOrdersListContext };
