import React from 'react';
import './SystemBanner.less';
import { useConfig } from 'providers';

const SystemBanner = () => {

  const { system } = useConfig();

  return (
    system?.banner?.enabled
      ? (
        <div className={'system-banner'}>
          <span dangerouslySetInnerHTML={{ __html: system?.banner.message }}/>
        </div>
      )
      : null
  );
};

export default SystemBanner;
